import React from 'react';
import DashboardRoutes from '../NavigationBar/DashboardRoutes';

function PageContents() {
    return (
        <>
            <div className="pageContent">
                <DashboardRoutes />
            </div>
        </>
    )
}

export default PageContents
