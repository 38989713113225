// import React from 'react'

// function YesterdayPerformApps() {
//     return (
//         <div>

//         </div>
//     )
// }

// export default YesterdayPerformApps


import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';

import { useNavigate } from 'react-router-dom';
// todays app data 
import { useGetAppYesterdayDownloadsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";



const YesterdayPerformApps = (props) => {


    let srNo = 0;

    const [yesterdayData, setYesterdayData] = useState([]);
    const [yesterdayCurrentPage, setYesterdayCurrentPage] = useState(1);
    const [yesterdayRowsPerPage, setYesterdayRowsPerPage] = useState(8); // Default value for rows per page
    const pageSizeOptions = [8, 15, 20]; // Options for rows per page
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
    const navigate = useNavigate();


    // API Call
    const { data: yesterdaysApp, isSuccess: isYesterdaySuccessData, refetch: YesterdaysData, isLoading: isYesterdayDataLoading } = useGetAppYesterdayDownloadsQuery();
    useEffect(() => {
        if (yesterdaysApp && isYesterdaySuccessData) {
            setYesterdayData(yesterdaysApp);

            setTimeout(() => {
                setShowLoadingAnimation(false);
            }, 1000);
        }
    }, [yesterdaysApp, isYesterdaySuccessData]);

    // console.log(AppData)


    // Calculate the index of the first and last rows of the current page
    const yesterdayIndexOfLastRow = yesterdayCurrentPage * yesterdayRowsPerPage;
    const yesterdayIndexOfFirstRow = yesterdayIndexOfLastRow - yesterdayRowsPerPage;

    //   const currentRows = data.slice(todayIndexOfFirstRow, todayIndexOfLastRow);
    // const todayTotalPages = Math.ceil(todaysApp.length / todayRowsPerPage);

    const currentRows = yesterdayData
        .filter((item) => item.is_delete === false)
        .slice(yesterdayIndexOfFirstRow, yesterdayIndexOfLastRow);

    // Function to handle pagination
    const handlePageChange = (pageNumber) => {
        setYesterdayCurrentPage(pageNumber);
    };

    // Function to handle rows per page change
    const handlePageSizeChange = (event) => {
        setYesterdayRowsPerPage(parseInt(event.target.value));
        setYesterdayCurrentPage(1); // Reset current page when changing rows per page
    };



    // hadmle playstore click 
    const handlePlayStoreClick = (package_name) => {

        window.open(`https://play.google.com/store/apps/details?id=${package_name}`)
    }

    // console.log(typeof(todayData))

    return (
        <>


            <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
                <Box sx={{ my: 2 }}>
                    <Typography color="primary">Yesterday's App Performance</Typography>
                </Box>

                <div className="data-table-container">
                    {isYesterdayDataLoading || props.showLoadingAnimation ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                            <CircularProgress />
                            <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                        </Box>
                    ) : (
                        <table className="table table-hover">
                            <thead>
                                <tr>

                                    <th >App Id</th>
                                    <th >App Logo</th>
                                    <th>Yesterday's Downloads</th>
                                    <th >App Name</th>
                                    <th >Other Details</th>
                                    <th  >Added By</th>
                                    <th>App Status</th>
                                    <th >Assign Ads</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentRows[0] ?

                                        currentRows.map((item, index) => (

                                            item.is_delete ? '' :

                                                <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'} >



                                                    {/* application Id  */}
                                                    <td><Typography sx={{ marginLeft: 1 }}>{item.id}</Typography></td>

                                                    {/* App Logo  */}
                                                    <td>
                                                        <Box >
                                                            <img src={item.app_logo} alt={item.title} height='50px' className='border border-1 rounded-circle ' style={{ boxShadow: "2px 2px 5px gray" }} />
                                                        </Box>
                                                    </td>

                                                    {/* App Downloads  */}
                                                    <td><Typography sx={{ marginLeft: 4 }}>{item.downloads}</Typography></td>

                                                    {/* app Name  */}
                                                    <td><Typography>{item.app_name}</Typography></td>



                                                    {/* other details  */}
                                                    <td>
                                                        <Typography
                                                            sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                            style={{ cursor: 'pointer' }}
                                                            title="Click to checkout on Play Store"
                                                            onClick={() => { handlePlayStoreClick(item.package_name) }}
                                                        >
                                                            {item.package_name}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '11px', display: "inline-block" }}>Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                        <Typography className='ms-5' sx={{ fontSize: '11px', display: "inline-block" }}>Update: {moment(item.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                    </td>


                                                    {/* Added User  */}
                                                    <td>
                                                        <Typography sx={{ mt: 2 }} style={{ color: 'gray' }}>{item.added_user}</Typography>
                                                    </td>




                                                    {/* App status  */}
                                                    <td>
                                                        {/* {item.app_status === 'Not Published'
                                                            ?
                                                            <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5 }} style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                            :
                                                            <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                        } */}

                                                        {item.app_status === 'Not Published'
                                                            ?
                                                            <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                            :
                                                            item.app_status === 'Published'
                                                                ?
                                                                <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                :
                                                                <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>

                                                        }
                                                    </td>

                                                    {/* Assign Ads */}
                                                    <td>
                                                        {
                                                            item.ads_type === "Main Ads"
                                                                ?
                                                                <Typography style={{ textShadow: '1px  3px 5px green' }}>{item.ads_type}</Typography>
                                                                :
                                                                <Typography style={{ textShadow: '1px  3px 5px orange' }}>{item.ads_type}</Typography>
                                                        }
                                                    </td>

                                                </tr>
                                        ))
                                        :
                                        <tr sx={{ backgroundColor: 'lightgray' }}>

                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><Typography sx={{ p: 2 }}>No Applications</Typography></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>


                                }
                            </tbody>
                        </table>
                    )}

                    {/* Pagination */}
                    <div className="pagination-container">

                        <div className="rows-per-page">
                            <span>Rows per page:</span>
                            <select
                                value={yesterdayRowsPerPage}
                                onChange={handlePageSizeChange}
                                className="select-box"
                            >
                                {pageSizeOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="pagination">
                            <button
                                onClick={() => handlePageChange(yesterdayCurrentPage - 1)}
                                disabled={yesterdayCurrentPage === 1}
                            >
                                &lt; {yesterdayCurrentPage - 1}
                            </button>
                            <button
                                onClick={() => handlePageChange(yesterdayCurrentPage)}
                                className="active"
                            >
                                {yesterdayCurrentPage}
                            </button>
                            <button
                                onClick={() => handlePageChange(yesterdayCurrentPage + 1)}
                                disabled={yesterdayIndexOfLastRow >= yesterdayData.length}
                            >
                                {yesterdayCurrentPage + 1} &gt;
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default YesterdayPerformApps













