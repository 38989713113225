// import { Box, Grid, Typography, Button } from "@mui/material"
// import ReplayIcon from '@mui/icons-material/Replay';
// import DownloadIcon from '@mui/icons-material/Download';
// import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// import { useGetTestAdsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
// import { useUpdateTestAdsMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";

// const TestAds = () => {

//   const [appOpenAds, setAppOpenAds] = useState('')
//   const [bannerAds, setBannerAds] = useState('')
//   const [nativeAds, setNativeAds] = useState('')
//   const [interstitialAds, setInterstitialAds] = useState('')
//   const [rewardedAds, setRewardedAds] = useState('')
//   const [mobileStickyAds, setMobileStickyAds] = useState('')
//   const [collapsibleAds, setCollapsibleAds] = useState('')
//   const [customAdsJson, setCustomAdsJson] = useState('{}')
//   const [extraDataField, setExtraDataField] = useState('{}')

//   const [customJsonError, setCustomJsonError] = useState('')
//   const [extraDataJsonError, setExtraDataJsonError] = useState('')
//   const [appData, setAppData] = useState([])

//   const navigate = useNavigate()

//   const { data: testAdsData, isSuccess, refetch, isLoading } = useGetTestAdsQuery()
//   const [updateResource, { isError }] = useUpdateTestAdsMutation()

//   useEffect(() => {
//     if (testAdsData && isSuccess) {
//       setAppData(testAdsData)
//       setAppOpenAds(testAdsData.app_open_ads)
//       setBannerAds(testAdsData.banner_ads)
//       setNativeAds(testAdsData.native_ads)
//       setInterstitialAds(testAdsData.interstitial_ads)
//       setRewardedAds(testAdsData.rewarded_ads)
//       setMobileStickyAds(testAdsData.mobile_sticky_ads)
//       setCollapsibleAds(testAdsData.collapsible_ads)
//       setCustomAdsJson(JSON.stringify(testAdsData.custom_ads_json , null , 5))
//       setExtraDataField(JSON.stringify(testAdsData.extra_data_field, null , 5))
//       refetch()
//     }

//   }, [testAdsData, isSuccess])


//   const handleTestAds = async () => {
//     const customJson = customAdsJson
//     const extraJson = extraDataField

//     // customAdsJson
//     // try{
//     //   setCustomAdsJson(JSON.parse(customJson))
//     // }catch{
//     //   setCustomJsonError('Value must be valid JSON.')
//     //   refetch()

//     // }

//     // // customAdsJson
//     // try{
//     //   setExtraDataField(JSON.parse(extraJson))
//     // }catch{
//     //   extraDataJsonError('Value must be valid JSON.')
//     //   refetch()

//     // }

//     const ads_data = {

//       "app_open_ads": appOpenAds,
//       "banner_ads": bannerAds,
//       "native_ads": nativeAds,
//       "interstitial_ads": interstitialAds,
//       "rewarded_ads": rewardedAds,
//       "mobile_sticky_ads": mobileStickyAds,
//       "collapsible_ads": collapsibleAds,

//       "custom_ads_json": JSON.parse(customAdsJson)  ,
//       "extra_data_field":extraJson ,
//     }
//     let serverError;
//     const res = await updateResource(ads_data)

//     if (res.data) {
//       // navigate('/dashboard/app-lists')
//       console.log('res.data :' , res.data)
//     }
//     if (res.error) {
//       serverError = res.error.data


//       if (serverError.custom_ads_json) {
//         setCustomJsonError(serverError.custom_ads_json)
//       }
//       if (serverError.extra_data_field) {
//         setExtraDataJsonError(serverError.extra_data_field)
//       }
//     }
//     // navigate('/dashboard/app-lists')

//   }


//   return (
//     <>
//       <Box >
//         <Box sx={{ mx: 2, mt: 2 }} >
//           <div>
//             <Typography color="primary">Test Ads</Typography>
//             <hr color="lightgray" />
//           </div>
//         </Box>

//         {/* dashboard */}
//         <Box sx={{ mx: 2, mt: 5, mb: 5 }}>


//           <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
//             <Typography sx={{ mx: 1, my: 2, }} style={{ display: 'inline-block' }} color="primary">Default Test Ads</Typography>

//             <Grid container spacing={2}>


//               <Grid item lg={6} xs={12} >

//                 <Box sx={{ p: 2 }}>
//                   {/* app open ads  */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="app_open_ads" className='form-label'>App Open Ads</label>
//                     <input type="text" id='app_open_ads' name='app_open_ads' className='form-control' value={appOpenAds} onChange={(e) => setAppOpenAds(e.target.value)} style={{ color: 'gray' }} />

//                   </Box>

//                   {/* nativeAds ads  */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="native_ads" className='form-label'>Native Ads</label>
//                     <input type="text" id='native_ads' name='native_ads' className='form-control' value={nativeAds} onChange={(e) => setNativeAds(e.target.value)} style={{ color: 'gray' }} />

//                   </Box>

//                   {/* rewarded_ads */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="interstitial_ads" className='form-label'>Rewarded Ads</label>
//                     <input type="text" id='rewarded_ads' name='rewarded_ads' className='form-control' value={rewardedAds} onChange={(e) => setRewardedAds(e.target.value)} style={{ color: 'gray' }} />

//                   </Box>

//                   {/* custom_ads_json */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="custom_ads_json" className='form-label'>Custom Ads Json</label>

//                     {customJsonError ? <Typography sx={{ color: 'red' }}>{customJsonError}</Typography> : ''}
//                     <textarea
//                       name="custom_ads_json"
//                       id="custom_ads_json"
//                       cols="30"
//                       rows="10"
//                       className="form-control"
//                       value={customAdsJson}
//                       style={{ color: 'gray' }}
//                       onChange={(e) => { setCustomAdsJson(e.target.value); setCustomJsonError('') }}
//                     />


//                   </Box>



//                 </Box>

//               </Grid>
//               <Grid item lg={6} xs={12} >

//                 <Box sx={{ p: 2 }}>
//                   {/* banner ads  */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="banner_ads" className='form-label'>Banner Ads</label>
//                     <input type="text" id='banner_ads' name='banner_ads' className='form-control' value={bannerAds} onChange={(e) => setBannerAds(e.target.value)} style={{ color: 'gray' }} />

//                   </Box>

//                   {/* interstitial_ads ads  */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="interstitial_ads" className='form-label'>Interstitial Ads</label>
//                     <input type="text" id='interstitial_ads' name='interstitial_ads' className='form-control' value={interstitialAds} onChange={(e) => setInterstitialAds(e.target.value)} style={{ color: 'gray' }} />

//                   </Box>

//                   {/* mobile_sticky_ads */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="mobile_sticky_ads" className='form-label'>Mobile Sticky Ads</label>
//                     <input type="text" id='mobile_sticky_ads' name='mobile_sticky_ads' className='form-control' value={mobileStickyAds} onChange={(e) => setMobileStickyAds(e.target.value)} style={{ color: 'gray' }} />

//                   </Box>

//                   {/* collapsible_ads */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="collapsible_ads" className='form-label'>Collapsible Ads</label>
//                     <input type="text" id='collapsible_ads' name='collapsible_ads' className='form-control' value={collapsibleAds} onChange={(e) => setCollapsibleAds(e.target.value)} style={{ color: 'gray' }} />

//                   </Box>


//                   {/* extra_data_field */}
//                   <Box sx={{ mb: 3 }}>
//                     <label htmlFor="extra_data_field" className='form-label'>Extra Data Field</label>

//                     {extraDataJsonError ? <Typography sx={{ color: 'red' }}>{extraDataJsonError}</Typography> : ''}

//                     <textarea
//                       name="extra_data_field"
//                       id="extra_data_field"
//                       cols="30"
//                       rows="10"
//                       className="form-control"
//                       value={extraDataField}
//                       style={{ color: 'gray', fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}
//                       onChange={(e) => { setExtraDataField(e.target.value); setExtraDataJsonError('') }}
//                     />
//                   </Box>


//                 </Box>

//               </Grid>

//             </Grid>

//             <Box textAlign='center'>
//               <Button onClick={() => { handleTestAds() }} sx={{ px: 3, mx: 1 }} variant="contained">Save</Button>
//               <Button sx={{ px: 3, color: 'white', mx: 1 }} className="bg-secondary" onClick={() => { navigate('/dashboard/app-lists') }}>cancel</Button>

//             </Box>
//           </Box>

//         </Box>

//       </Box>
//     </>
//   )
// }

// export default TestAds




import { Button, Box, Typography, Grid, Tabs, Tab, } from "@mui/material"

import { useNavigate} from 'react-router-dom';

import { useGetTestAdsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useSelector, useDispatch } from 'react-redux';

import { useUpdateTestAdsMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useEffect, useState } from 'react';

import { useGetAdsMonetizePlatformsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";



const ApplicationDetails = () => {
  const { access_token } = useSelector(state => state.auth);

  const AppId = 1;
  const navigate = useNavigate()

  const [appData, setAppData] = useState([])

  const [appOpenAds, setAppOpenAds] = useState('')
  const [bannerAds, setBannerAds] = useState('')
  const [nativeAds, setNativeAds] = useState('')
  const [interstitialAds, setInterstitialAds] = useState('')
  const [rewardedAds, setRewardedAds] = useState('')
  const [mobileStickyAds, setMobileStickyAds] = useState('')
  const [collapsibleAds, setCollapsibleAds] = useState('')

  const [tabValue, setTabValue] = useState(0);
  const [monetizePlatform, setMonetizePlatform] = useState([])


  // error states 

  const { data: getMonetize, isSuccess: getMonetizeSuccess, refetch: monetizeRefetch } = useGetAdsMonetizePlatformsQuery(access_token)



  // const [updateAppDetails, { isError }] = useAppFieldUpdateMutation()
  const { data: appDetails, isSuccess, refetch, isLoading } = useGetTestAdsQuery(AppId)
  const [updateResource, { isError }] = useUpdateTestAdsMutation()

  useEffect(() => {
    if (appDetails && isSuccess) {

      setAppOpenAds(appDetails.app_open_ads)
      setBannerAds(appDetails.banner_ads)
      setNativeAds(appDetails.native_ads)
      setInterstitialAds(appDetails.interstitial_ads)
      setRewardedAds(appDetails.rewarded_ads)
      setMobileStickyAds(appDetails.mobile_sticky_ads)
      setCollapsibleAds(appDetails.collapsible_ads)

      refetch()
    }

  }, [appDetails, isSuccess])

  useEffect(() => {
    if (getMonetize && getMonetizeSuccess) {
      setMonetizePlatform(getMonetize)
      monetizeRefetch()
      setTabValue(getMonetize[0]["id"] - 1) // to set monetize platform initial state 
      // console.log('tabValue :',tabValue)
      // setSelectedOptions(getMonetize.map((platform) => platform.monetize_platformname))

    }
  }, [getMonetize, isSuccess])

  // console.log('appOpenAds :',appOpenAds)


  const handleAppsDetails = async () => {




    const formData = {

      "app_open_ads": appOpenAds || null,
      "banner_ads": bannerAds || null,
      "native_ads": nativeAds || null,
      "interstitial_ads": interstitialAds || null,
      "rewarded_ads": rewardedAds || null,
      "mobile_sticky_ads": mobileStickyAds || null,
      "collapsible_ads": collapsibleAds || null,


    }


    // making patch request 
    let serverError;
    const res = await updateResource({ AppId, formData })
    if (res.data) {
      // console.log('res.data ', res.data)
      // setExtraDataJsonError('')
      navigate('/app-lists')
    }
    if (res.error) {
      console.log(res.error)
      serverError = res.error.data



    }


  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    // tabValue, setTabValue
  };



  const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
      <div role='tabpanel' hidden={value !== index}>
        {
          value === index && (<Box>{children}</Box>)
        }

      </div>
    )
  }

  let srNo = -1;

  return (

    <><Box>
      <Box>
        <Box sx={{ mx: 2, mt: 2 }} >
          <div>
            <Typography color="primary">Default Test Ads</Typography>
            <hr color="lightgray" />
          </div>
        </Box>
      </Box>





      {/* application details and Ads  */}
      <Box sx={{ mx: 2, mt: 1, mb: 5 }}>

        <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>



          <Tabs value={tabValue} textColor="primary" indicatorColor="primary" onChange={handleChange} variant="scrollable" sx={{ maxWidth: { xs: "60%", sm: "90%", md: "90%", lg: "90%" }, bgcolor: 'background.paper' }}>


            {
              monetizePlatform.map((platform) => (
                <Tab label={platform.monetize_platformname} sx={{ textTransform: 'none', fontWeight: 'bold' }} key={platform.id}></Tab>
              ))
            }

          </Tabs>

          {
            monetizePlatform.map((platform) => (
              <TabPanel value={tabValue} index={srNo += 1} key={platform.id}>

                {/* application details and Ads  */}
                <Box sx={{ mx: 2 }}>
                  <Box sx={{ p: 2, borderRadius: 2, overflow: 'hidden' }}>

                    <Typography sx={{ p: 2, backgroundColor: 'lightblue', borderRadius: 1, width: "48%", mx: 1, mb: 2 }}>{platform.monetize_platformname} Test Ads</Typography>

                    <Grid container spacing={2}>

                      {/* applications  */}
                      <Grid item lg={6} xs={12} >

                        <Box sx={{ p: 1 }}>

                          {/* app open ads  */}
                          <Box sx={{ mb: 3 }}>
                            <label className='form-label'>App Open Ads</label>


                            <input
                              type="text"

                              className='form-control'
                              value={appOpenAds[platform.monetize_platformname] || ''}
                              onChange={(e) => {
                                const platformName = platform.monetize_platformname;
                                setAppOpenAds((prevAds) => ({
                                  ...prevAds,
                                  [platformName]: e.target.value
                                }));
                              }}
                              style={{ color: 'gray' }}
                            />




                          </Box>


                          {/* nativeAds ads  */}
                          <Box sx={{ mb: 3 }}>
                            <label className='form-label'>Native Ads</label>



                            <input
                              type="text"

                              className='form-control'
                              value={nativeAds[platform.monetize_platformname] || ''}
                              onChange={(e) => {
                                const platformName = platform.monetize_platformname;
                                setNativeAds((prevAds) => ({
                                  ...prevAds,
                                  [platformName]: e.target.value
                                }));
                              }}
                              style={{ color: 'gray' }}
                            />

                          </Box>


                          {/* rewarded_ads */}
                          <Box sx={{ mb: 3 }}>
                            <label className='form-label'>Rewarded Ads</label>

                            <input
                              type="text"

                              className='form-control'
                              value={rewardedAds[platform.monetize_platformname] || ''}
                              onChange={(e) => {
                                const platformName = platform.monetize_platformname;
                                setRewardedAds((prevAds) => ({
                                  ...prevAds,
                                  [platformName]: e.target.value
                                }));
                              }}
                              style={{ color: 'gray' }}
                            />

                          </Box>


                          {/* collapsible_ads */}
                          <Box >
                            <label className='form-label'>Collapsible Ads</label>
                            {/* <input type="text" id='collapsible_ads' name='collapsible_ads' className='form-control' value={collapsibleAds !== null ? collapsibleAds : 'No Ads'} onChange={(e) => setCollapsibleAds(e.target.value)} style={{ color: 'gray' }} /> */}


                            <input
                              type="text"

                              className='form-control'
                              value={collapsibleAds[platform.monetize_platformname] || ''}
                              onChange={(e) => {
                                const platformName = platform.monetize_platformname;
                                setCollapsibleAds((prevAds) => ({
                                  ...prevAds,
                                  [platformName]: e.target.value
                                }));
                              }}
                              style={{ color: 'gray' }}
                            />

                          </Box>




                        </Box>
                      </Grid>

                      {/* Ads  */}
                      <Grid item lg={6} xs={12}>

                        <Box sx={{ p: 1 }}>



                          {/* banner ads  */}
                          <Box sx={{ mb: 3 }}>
                            <label className='form-label'>Banner Ads</label>
                            {/* <input type="text" id='banner_ads' name='banner_ads' className='form-control' value={bannerAds !== null ? bannerAds : 'No Ads'} onChange={(e) => setBannerAds(e.target.value)} style={{ color: 'gray' }} /> */}

                            <input
                              type="text"

                              className='form-control'
                              value={bannerAds[platform.monetize_platformname] || ''}
                              onChange={(e) => {
                                const platformName = platform.monetize_platformname;
                                setBannerAds((prevAds) => ({
                                  ...prevAds,
                                  [platformName]: e.target.value
                                }));
                              }}
                              style={{ color: 'gray' }}
                            />

                          </Box>



                          {/* interstitial_ads ads  */}
                          <Box sx={{ mb: 3 }}>
                            <label className='form-label'>Interstitial Ads</label>
                            {/* <input type="text" id='interstitial_ads' name='interstitial_ads' className='form-control' value={interstitialAds !== null ? interstitialAds : 'No Ads'} onChange={(e) => setInterstitialAds(e.target.value)} style={{ color: 'gray' }} /> */}

                            <input
                              type="text"

                              className='form-control'
                              value={interstitialAds[platform.monetize_platformname] || ''}
                              onChange={(e) => {
                                const platformName = platform.monetize_platformname;
                                setInterstitialAds((prevAds) => ({
                                  ...prevAds,
                                  [platformName]: e.target.value
                                }));
                              }}
                              style={{ color: 'gray' }}
                            />

                          </Box>



                          {/* mobile_sticky_ads */}
                          <Box >
                            <label className='form-label' >Mobile Sticky Ads</label>
                            {/* <input type="text" id='mobile_sticky_ads' name='mobile_sticky_ads' className='form-control' value={mobileStickyAds !== null ? mobileStickyAds : 'No Ads'} onChange={(e) => setMobileStickyAds(e.target.value)} style={{ color: 'gray' }} /> */}

                            <input
                              type="text"

                              className='form-control'
                              value={mobileStickyAds[platform.monetize_platformname] || ''}
                              onChange={(e) => {
                                const platformName = platform.monetize_platformname;
                                setMobileStickyAds((prevAds) => ({
                                  ...prevAds,
                                  [platformName]: e.target.value
                                }));
                              }}
                              style={{ color: 'gray' }}
                            />

                          </Box>


                        </Box>
                      </Grid>
                    </Grid>

                  </Box>

                </Box>

              </TabPanel>
            ))
          }

          <Box textAlign='center'>
            <Button onClick={() => { handleAppsDetails(appData.id) }} sx={{ px: 3, mx: 1 }} variant="contained">Save</Button>
            <Button sx={{ px: 3, color: 'white', mx: 1 }} className="bg-secondary" onClick={() => { navigate('/app-lists') }}>cancel</Button>

          </Box>



        </Box>

      </Box>
    </Box >
    </>
  )
}

export default ApplicationDetails



