import { TextField, Button, Grid, Card, Typography, Tabs, Tab, Box, Alert } from "@mui/material"
import Pic1 from '../../Images/dashboard.png'
import { NavLink, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useLoginUserMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { getToken, storeToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService"

import { useDispatch } from "react-redux"
import { setUserToken } from '../../ApiCalls/AuthenticationsApi/features/authSlice'

// add Login History 
import { useAddUserHistoryMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"




const getCurrentDateTime = () => {

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;

}


const UserLogin = () => {

    // add user login history 
    const [addUserHistory, { isLoading: isLoadingaddUserHistory, isError: isErroraddUserHistory }] = useAddUserHistoryMutation()


    const [loginUser, { isLoading }] = useLoginUserMutation()

    
    const dispatch = useDispatch()

    const [server_error, setServerError] = useState({})
    const [error, setError] = useState({
        state: false,
        msg: '',
        error: ''
    })

    const navigate = useNavigate()

    const onClose = () => {
        setError({
            state: false,
            msg: '',
            error: ''
        })
    }

    const submitHandler = async (e) => {
        e.preventDefault()

        const data = new FormData(e.currentTarget)
        const actualData = {
            email: data.get('email'),
            password: data.get('password')
        }
        // console.log(actualData)

        const res = await loginUser(actualData)

        // console.log(res)
        if (res.data) {
            setError({
                state: true,
                msg: res.data.msg,
                error: 'success'
            })


            // Store token into a localstorage 
            storeToken(res.data.token)
            let { access_token } = getToken()
            dispatch(setUserToken({ access_token: access_token }))

            

            //=================== Add Login History ===================  
            const formattedDateTime  = getCurrentDateTime()

            // console.log('formattedDateTime :', formattedDateTime)

            const histData = {
                "user_name": res.data.userProfile.name,
                "task_time": formattedDateTime,
                "task": 'User Login'
            }

            // console.log('histData :', histData)

            const resp = await addUserHistory({ histData, access_token })
            // console.log('resp ', resp)

            //=================== end Login History ===================  


            setTimeout(() => {
                onClose();
                navigate('/')
            }, 2000)

        }
        if (res.error) {
            if (res.error.data.errors.non_field_errors) {
                setError({
                    state: true,
                    msg: res.error.data.errors.non_field_errors[0],
                    error: 'error'
                })
                setServerError({})
                setTimeout(() => { onClose() }, 5000)

            } else {

                setServerError(res.error.data.errors)
                setTimeout(() => { onClose() }, 5000)
            }
        }
    }

    // this code prevent login page refresh 
    let { access_token } = getToken()
    useEffect(() => {
        let { access_token } = getToken()
        dispatch(setUserToken({ access_token: access_token }))

    }, [access_token, dispatch])







    return (
        <>

            <Box sx={{ pt: 3, mt: 10 }}>

                <Grid container sx={{ height: '40vh', margin: '5vh', mt: 3, width: '90%' }}>
                    <Grid item lg={5} md={7} sx={{ mt: 8 }}>



                        <Box component='form' id='login-form' noValidate sx={{ p: 3 }} onSubmit={submitHandler}>

                            {/* show Error  */}
                            {error.error ? <Alert severity={error.error} onClose={onClose}>{error.msg}</Alert> : ''}

                            {/* email field  */}
                            <TextField required margin="normal" fullWidth variant="standard" size="small" id='email' name='email' label='Email Address' />
                            {server_error.email ? <Typography style={{ fontSize: 11, color: 'red' }}>{server_error.email[0]}</Typography> : ''}

                            {/* password field  */}
                            <TextField required margin="normal" fullWidth variant="standard" size="small" id='password' name='password' label='Password' type="password" />
                            {server_error.password ? <Typography style={{ fontSize: 11, color: 'red' }}>{server_error.password[0]}</Typography> : ''}

                            <Box textAlign='center'>
                                <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Login</Button>
                            </Box>
                            <NavLink to='reset-password'>Forget Password ?</NavLink>

                        </Box>

                    </Grid>



                    <Grid item lg={7} md={5} sx={{
                        backgroundImage: `url(${Pic1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: { sx: 'none', sm: "block" }
                    }}>

                    </Grid>

                </Grid>

            </Box>
        </>
    )
}

export default UserLogin
