



import { Box, TextField, Button, Typography, Alert, Grid, Card, CardContent, CircularProgress } from "@mui/material"

import { useEffect, useState } from "react"

// Hook to get all user data 
import { useRegisterUserMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi'
import { useGetAllUserProfileQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { useRemoveUserMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { useGetLoggedUserQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"

import { getToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService"


// time formate 
import moment from 'moment/moment';

// Show tables 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// icon 
import DeleteIcon from '@mui/icons-material/Delete';
import SyncLockOutlinedIcon from '@mui/icons-material/SyncLockOutlined';

// changeUserPassword 
import { useChangeUserPasswordByAdminMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import AllUserLogs from "./AllUserLogs"
import { useNavigate } from "react-router-dom"






const Users = () => {
  const navigate = useNavigate()
  // handle all users logs 
  const handleAllUserPage = () => {
    navigate('/all-users-logs')
  }
  // animation 
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);


  // get loged in user data 
  const { access_token } = getToken()
  const { data: logedUser } = useGetLoggedUserQuery(access_token)

  const [removeUser] = useRemoveUserMutation()

  // =========================================== fetch All users Logic start here    =======================================================================
  const [userProfile, setUserProfile] = useState([])


  const { data: profiles, isSuccess, refetch, isLoading } = useGetAllUserProfileQuery(access_token)

  useEffect(() => {
    if (profiles && isSuccess) {
      setShowLoadingAnimation(true);

      setUserProfile(profiles)

      setTimeout(() => {
        setShowLoadingAnimation(false);
      }, 1000);
    }
    refetch()

  }, [profiles, isSuccess])
  // console.log('all users  ', userProfile)


  // ======================================== Add user Form Start From Here ========================================================


  // for api call 
  const [server_error, setServerError] = useState({})
  const [error, setError] = useState({
    state: false,
    msg: '',
    error: ''
  })

  const [registerUser] = useRegisterUserMutation()

  const onClose = () => {
    setError({
      state: false,
      msg: '',
      error: ''
    })
  }

  const registerHandler = async (e) => {
    e.preventDefault()

    const data = new FormData(e.currentTarget)

    const actualData = {
      name: data.get('name'),
      email: data.get('email'),
      password: data.get('password'),
      password2: data.get('password2'),
      tc: true
    }

    // // call api 
    // const res = await registerUser(actualData)
    const res = await registerUser({ actualData, access_token })


    if (res.data) {

      setError({
        state: true,
        msg: res.data.msg,
        error: 'success'
      })

      setTimeout(() => { onClose() }, 3000)
      setServerError({})
      handleCancle()
      refetch()   //Refetch all users from api 

    }
    if (res.error) {

      if (res.error.data.errors.non_field_errors) {
        setError({
          state: true,
          msg: res.error.data.errors.non_field_errors[0],
          error: 'error'
        })

      } else {

        setServerError(res.error.data.errors)
        return false
      }

    }

  }

  const handleCancle = () => {
    document.getElementById('register-form').reset()
  }

  const handleAddUserShowPass = () => {
    const pass1 = document.getElementById('password');
    const pass2 = document.getElementById('password2');

    if (pass1.type === "password" && pass2.type === "password") {

      pass1.type = "text"
      pass2.type = "text"
    } else {
      pass1.type = "password"
      pass2.type = "password"
    }


  }




  //============================== Delete User Logic ==========================================

  const handleDeleteUser = async (id) => {

    if (logedUser.id === id) {
      alert('You can not remove your own user')
    }
    else {
      const x = window.confirm('Do you want to delete this user ?')
      if (x) {
        const res = await removeUser({ id, access_token })
        refetch()   //Refetch all users from api 
        if (res.error) {
          alert('Something Went Wrong')
        }


      }
    }
  }


  // ====================================================== reset password Flag ============================================
  const [resetPasswordFlag, setResetPasswordFlag] = useState(false)
  const [resetUserId, setResetUserId] = useState('')
  const [resetUserName, setResetUserName] = useState('')

  // password not equal state error 
  const [changePassError, setChangePassError] = useState(
    {
      state: false,
      msg: "",
      error: ''
    })


  // onclose for password Change 
  const onChnagePassClose = () => {
    setChangePassError({
      state: false,
      msg: "",
      error: ''
    })
  }

  // set changePassError emty after 3 second 
  const resetChangPassError = () => {
    setTimeout(() => {
      onChnagePassClose()
    }, 3000)
  }



  // handle password change icon to switch add user to change user pass 
  const handleChangePassword = (profileId, profileName) => {
    setResetUserId(profileId)
    setResetUserName(profileName)
    setResetPasswordFlag(true)
  }






  // show changePassword 
  const handleShowChangePass = () => {
    const pass1 = document.getElementById('newPass1');
    const pass2 = document.getElementById('newPass2');

    if (pass1.type === "password" && pass2.type === "password") {

      pass1.type = "text"
      pass2.type = "text"
    } else {
      pass1.type = "password"
      pass2.type = "password"
    }


  }

  // Change User Password after call Api 

  const [changePassword] = useChangeUserPasswordByAdminMutation()

  const ChangeUserPassword = async () => {


    const newPass1 = document.getElementById('newPass1').value;
    const newPass2 = document.getElementById('newPass2').value;


    // check empty password 
    if (!newPass1 || !newPass2) {
      // check empty pass1 
      if (!newPass1) {
        document.getElementById('passMsg1').innerHTML = "Password field should not be empty";

      } else {
        document.getElementById('passMsg1').innerHTML = "";
      }

      // check empty pass2
      if (!newPass2) {
        document.getElementById('passMsg2').innerHTML = "Confirm Password field should not be empty";
      } else {
        document.getElementById('passMsg2').innerHTML = "";

      }

    } else {

      // clear Error Msg 
      document.getElementById('passMsg1').innerHTML = "";
      document.getElementById('passMsg2').innerHTML = "";


      if (newPass1 !== newPass2) {

        setChangePassError({
          state: true,
          msg: "password and confirm password not match ",
          error: 'error'
        })
        // reset Password error after 3 sec 
        resetChangPassError()

      } else {
        if (newPass1.length <= 3) {
          setChangePassError({
            state: true,
            msg: "password should be more than 3 characters ",
            error: 'error'
          })
          // reset Password error after 3 sec 
          resetChangPassError()

        } else {
          // API call to change password 
          const actualData = {
            "new_password": newPass1
          }
          const res = await changePassword({ actualData, access_token, resetUserId })
          if (res.data) {
            setChangePassError({
              state: true,
              msg: `${resetUserName}'s password change successfully `,
              error: 'success'
            })
            // reset Password error after 3 sec 
            resetChangPassError()
            setTimeout(() => {

              setResetPasswordFlag(false)
            }, 5000)
          }
          if (res.error) {
            setChangePassError({
              state: true,
              msg: `Something went wrong`,
              error: 'error'
            })
            // reset Password error after 3 sec 
            resetChangPassError()
          }


        }


      }

    }

  }


  // change to add use 

  const addUserFromChange = () => {
    setResetPasswordFlag(false)
  }





  const AddUserForms = () => {


    return (
      <>
        <Box >
          <Box>
            {error.error ? <Alert severity={error.error} onClose={onClose}>{error.msg}</Alert> : ''}
          </Box>

          <Typography color='primary'>Add User</Typography>

          <Box component='form' noValidate onSubmit={registerHandler} id="register-form">

            {/* name field with error  */}
            <TextField required fullWidth size="small" label='Name' variant="standard" margin="normal" name="name" id="name" type="text" />
            {server_error.name ? <Typography style={{ fontSize: 11, color: 'red' }}>{server_error.name[0]}</Typography> : ''}

            {/* email field with error  */}
            <TextField required fullWidth size="small" label='Email Address' variant="standard" margin="normal" name="email" id="email" type="email" />
            {server_error.email ? <Typography style={{ fontSize: 11, color: 'red' }}>{server_error.email[0]}</Typography> : ''}

            {/* password field with error  */}
            <TextField required fullWidth size="small" label='Password' variant="standard" margin="normal" name="password" id="password" type="password" />
            {server_error.password ? <Typography style={{ fontSize: 11, color: 'red' }}>{server_error.password[0]}</Typography> : ''}

            {/* confirm field with error  */}
            <TextField required fullWidth size="small" label='Confirm password' variant="standard" margin="normal" name="password2" id="password2" type="password" />
            {server_error.password2 ? <Typography style={{ fontSize: 11, color: 'red' }}>{server_error.password2[0]}</Typography> : ''}

            {/* Check box field with error  */}
            {/* <FormControlLabel label="terms and condition" control={<Checkbox value={true} color="primary" id="tc" name="tc" />} />
            {server_error.tc ? <Typography style={{ fontSize: 11, color: 'red' }}>Please Check terms & condition</Typography> : ''} */}


            <div className="form-check my-4">
              <input className="form-check-input" type="checkbox" value="" id="showPass" onClick={handleAddUserShowPass} />
              <label className="form-check-label" for="showPass">
                Show password
              </label>
            </div>


            <Box textAlign='center'>
              <Button type="submit" variant="contained" sx={{ px: 3, mt: 1, mx: 1 }}>Add</Button>
            </Box>

          </Box>

        </Box>
      </>
    )
  }

  // ======================================== Add user Form End From Here ========================================================
  //==============================================================================================================================

  // ======================================== Show All user Form Start From Here =================================================


  const AllUsersProfile = () => {
    let srNo = 0
    return (
      <>
        <Box sx={{ p: 1 }}>
          <Typography color='primary'>All Users</Typography>

        </Box>
        <Box className="" style={{ maxHeight: '600px', overflowY: 'scroll' }}>
          <TableContainer >

            {isLoading || showLoadingAnimation ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                <CircularProgress />
                <Typography sx={{ ml: 2 }}>Loading Users...</Typography>
              </Box>
            ) : (
              <Table className="table  table-hover">
                <TableHead>
                  <TableRow>
                    <TableCell ><b style={{ color: 'gray' }}></b></TableCell>
                    <TableCell ><b style={{ color: 'gray' }}>Name</b></TableCell>
                    <TableCell ><b style={{ color: 'gray' }}>Email</b></TableCell>
                    <TableCell ><b style={{ color: 'gray' }}>Created </b></TableCell>
                    <TableCell ><b style={{ color: 'gray' }}>Update Password </b></TableCell>
                    <TableCell ><b style={{ color: 'gray', marginLeft: 40 }}>Action </b></TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>




                  {userProfile.map((profile) => (
                    profile.id === logedUser.id || profile.email === 'junaidcoder@gmail.com' ? '' :


                      <TableRow key={profile.id}>

                        <TableCell component="th" scope="row" >{srNo += 1}</TableCell>

                        <TableCell component="th" scope="row" sx={{ cursor: "pointer" }} onClick={() => { navigate(`/edit-user-profile/${profile.id}`) }}><Typography >{profile.name}</Typography></TableCell>

                        <TableCell align="left" sx={{ color: '#1976d2' }}>{profile.email}</TableCell>
                        <TableCell align="left" sx={{ color: profile.is_admin ? '#1976d2' : '' }}>{moment(profile.created_at).format('MMMM Do YYYY, h:mm a')}</TableCell>
                        <TableCell align="left" sx={{ color: profile.is_admin ? '#1976d2' : '' }}>{moment(profile.updated_at).format('MMMM Do YYYY, h:mm a')}</TableCell>

                        <TableCell align="left" >

                          {/* // Delete User Button  */}
                          {profile.is_admin ? <Typography color="primary" sx={{ marginLeft: 5, fontSize: 14 }}>Admin</Typography> :
                            <>
                              <Button sx={{ color: 'primary', borderColor: "primary" }} onClick={() => { handleChangePassword(profile.id, profile.name) }} title="Change user password"><SyncLockOutlinedIcon /></Button>
                              <Button sx={{ color: 'red' }} onClick={() => { handleDeleteUser(profile.id) }} title="Delete user"><DeleteIcon /></Button>

                            </>
                          }

                        </TableCell>

                      </TableRow>
                  )
                  )
                  }




                </TableBody>
              </Table>
            )}
          </TableContainer >
        </Box >

      </>
    )
  }


  // Reset Password with admin access 
  const ResetPassword = () => {



    return (
      <>
        <Box >
          <Box textAlign="right" sx={{ mb: 3 }}>
            <Button sx={{ px: 3, mt: 1, mx: 1 }} onClick={() => { addUserFromChange() }}>Add User</Button>

          </Box>

          <Typography color='primary'>Change <strong>{resetUserName}'s</strong> Password  </Typography>


          <Box sx={{ my: 2 }}>
            {changePassError.error ? <Alert severity={changePassError.error} onClose={onChnagePassClose}>{changePassError.msg}</Alert> : ''}
          </Box>


          {/* password field with error  */}
          <TextField required fullWidth size="small" label='New Password' variant="standard" margin="normal" name="newPass1" id="newPass1" type="password" />
          <p style={{ color: "red" }} id="passMsg1"></p>


          {/* confirm field with error  */}
          <TextField required fullWidth size="small" label='Confirm New password' variant="standard" margin="normal" name="newPass2" id="newPass2" type="password" />
          <p style={{ color: "red" }} id="passMsg2"></p>



          <div className="form-check my-4">
            <input className="form-check-input" type="checkbox" value="" id="showPass" onClick={handleShowChangePass} />
            <label className="form-check-label" for="showPass">
              Show password
            </label>
          </div>


          <Box textAlign='center'>
            <Button type="submit" variant="contained" sx={{ px: 3, mt: 1, mx: 1 }} onClick={ChangeUserPassword}>Change Password</Button>
          </Box>



        </Box>

      </>
    )
  }




  // ======================================== Show All user Form End From Here ===================================================





  return (
    <>
      <Box >
        <Box sx={{ mx: 2, mt: 2 }} >
          <div>
            <Typography color="primary">Users</Typography>
            <hr color="lightgray" />
          </div>




          <Box>
            <Box sx={{ mt: 2 }} textAlign="end">
              <Button onClick={handleAllUserPage}>All Users Logs</Button>
            </Box>

            <Grid container sx={{ mt: 3 }} >

              {/* ===================================== Left Section Show All Users  =====================================*/}
              <Grid item lg={8} md={8} xs={12} sx={{ mx: 2, mt: 1 }} >

                <Box id='user-icon'  >

                  <Card sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2 }}>
                    <CardContent>
                      <Box>
                        <AllUsersProfile />
                      </Box>

                    </CardContent>
                  </Card>
                </Box>
              </Grid>


              {/* ===================================== Right Section Change Password =====================================*/}

              <Grid item lg={3} md={3} xs={12} sx={{ mx: 2, mt: 1 }} >


                <Box id='user-icon'  >

                  <Card sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2 }}>
                    <CardContent>


                      {resetPasswordFlag ?
                        <ResetPassword />
                        :
                        <AddUserForms />
                      }

                    </CardContent>
                  </Card>


                </Box>

              </Grid>
            </Grid>
          </Box>

        </Box >



      </Box >
    </>
  )
}

export default Users



