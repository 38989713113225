import { Box, Typography, Button, Grid } from "@mui/material"
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom"

import { useGetpublishPlatformQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi'
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';


const AppPublishedPlateForm = () => {
    const navigate = useNavigate()
    const [publishPlatform, setPublishPlatform] = useState([])

    const { access_token } = useSelector(state => state.auth);
    const { data, isSuccess, refetch } = useGetpublishPlatformQuery(access_token)

    useEffect(() => {
        if (data && isSuccess) {
            setPublishPlatform(data)
            refetch()
        }
    }, [data, isSuccess])

    // console.log(data)

    const addPlatformHandler = () => {
        navigate('/add-publish-platform')
    }

    return (
        <>

            <Box >
                <Box sx={{ mx: 2, mt: 2 }} >
                    <div>
                        <Typography color="primary"><NavLink to='/platform' sx={{ color: 'primary' }}>Platform</NavLink> / App Publish Platform</Typography>
                        <hr color="lightgray" />
                    </div>
                </Box>

                <Box sx={{ mx: 2, mt: 2 }}>
                    <Box sx={{ mt: 1 }} textAlign='right'>
                        <Button  variant="contained" onClick={addPlatformHandler} >Add Platform</Button>
                    </Box >

                    <Box sx={{ px: 5, mt: 3, borderRadius: 2, boxShadow: 5 }}>
                        <Grid container spacing={2}>
                            {publishPlatform.map((platform) => (
                                <Box sx={{ display: 'inline', p: 4, mx: 1 }} key={platform.id}>

                                    <Link to="">
                                        {/* <div style={{display:'inline-block'}}> */}
                                        <Box style={{ display: 'inline' }} >
                                            <img src={platform.platformlogo} alt="No Image" height='100px' />

                                        </Box>
                                        <Typography sx={{ mx: 2, mt: 1, fontSize: 14 }}>{platform.platformname}</Typography>
                                        {/* </div> */}


                                    </Link>
                                </Box>
                            ))}

                        </Grid>



                    </Box>

                </Box>
            </Box>
        </>
    )
}

export default AppPublishedPlateForm
