
import { Button, Grid, Card, Typography, Box, Alert, CardContent } from "@mui/material"
import { AnimatePresence, motion } from "framer-motion"
import user_avatar from '../../Images/user_avatar.png'
import ChangeUserPassword from "./ChangeUserPassword"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

import { useGetLoggedUserQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { getToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService"

const UserProfile = () => {

    const [showChangePass, setShowChangePass] = useState(false)

    // call APi to get user data 
    const [userData, setUserData] = useState({
        email: '',
        name: '',
        is_admin: ''
    })

    const { access_token } = getToken()
    const { data, } = useGetLoggedUserQuery(access_token)
    // console.log(data)
    // setUsername(data.name)


    const handleShowChangePass = (e) => {
        e.preventDefault()

        setShowChangePass(!showChangePass)
    }


    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: { duration: 0.5 },
        },
        show: {
            width: 'auto',
            transition: { duration: 0.5 },
            opacity: 1
        }

    }

    useEffect(() => {
        if (data) {
            // console.log('userData.is_admin ', userData)
            setUserData({
                email: data.email,
                name: data.name,
                is_admin: data.is_admin
            })
        }
    }, [data])


    return (
        <>
            <Box >
                <Box sx={{ mx: 2, mt: 2 }} >
                    <Box>
                        <Typography color='primary'>
                            Profile
                        </Typography>
                        <hr color="lightgray" />
                    </Box>
                </Box>


                <Grid container sx={{ mt: 5 }} >

                    {/* ===================================== Left Section  =====================================*/}
                    <Grid item lg={4} md={7} xs={12} sx={{ mx: 2, mt: 1 }}>

                        {/* ===================================== Top Section User Avatar  =====================================*/}
                        <Box id='user-icon'  >

                            <Card sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray' }}>
                                <CardContent>
                                    <Box textAlign='center'>
                                        <img src={user_avatar} alt="No avatar" height='180px' />
                                    </Box>


                                    <Box textAlign='center' >
                                        <Typography color='primary'>
                                            {userData.name}
                                        </Typography>

                                        <Typography fontSize='small'>Developer</Typography>
                                    </Box>

                                </CardContent>
                            </Card>
                        </Box>


                        {/* ===================================== Top Section User Details  =====================================*/}
                        <Card sx={{ minWidth: 275, mt: 2, boxShadow: '2px 2px 8px gray', p: 5 }}>
                            <CardContent>
                                <Box >
                                    <Typography sx={{ borderBottom: '1px solid gray', pb: 2, color: 'gray' }}>User Details</Typography>
                                </Box>


                                <Box sx={{ mt: 2 }}>
                                    <Box>
                                        <Typography fontSize='small' color='gray' sx={{ mt: 1, display: 'flex' }}>
                                            Name
                                            <Typography sx={{ mx: 3 }} fontSize='small'>{userData.name}</Typography>
                                        </Typography>

                                    </Box>

                                    <Box>
                                        <Typography fontSize='small' color='gray' sx={{ mt: 1, display: 'flex' }}>
                                            Email
                                            <Typography sx={{ mx: 3 }} fontSize='small'>{userData.email}</Typography>
                                        </Typography>

                                    </Box>

                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>


                    {/* ===================================== Right Section Change Password =====================================*/}

                    <Grid item lg={7} md={5} xs={12} sx={{ mx: 2, mt: 1 }} >


                        <Box id='user-icon'  >




                            <Card sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 5 }}>
                                {data.is_admin ?


                                    <CardContent>


                                        {!showChangePass ? <Button variant="contained" onClick={handleShowChangePass}>Change Password</Button>
                                            :
                                            <NavLink  ><Typography sx={{ my: 2 }} color='primary' onClick={handleShowChangePass}>Change Password</Typography></NavLink>}

                                        <AnimatePresence>
                                            {showChangePass && <motion.div initial="hidden" animate="show" exit="hidden" variants={showAnimation}><ChangeUserPassword /></motion.div>}
                                        </AnimatePresence>

                                    </CardContent>
                                    :
                                    <Box sx={{my:4}}>
                                        <Typography sx={{my:4 }} color="primary">CHANGE PASSWORD</Typography>

                                        <Typography>Note.</Typography>
                                        <Typography>Contact <strong>Admin</strong> to change your password</Typography>

                                    </Box>
                                }

                            </Card>



                        </Box>

                    </Grid>
                </Grid>

            </Box >
        </>
    )
}

export default UserProfile
