import { TextField, Button, Grid, Card, Typography, Tabs, Tab, Box, Alert } from "@mui/material"
import Pic1 from '../../Images/Forgot_password.png'
import { NavLink, useNavigate } from "react-router-dom"
import { useState } from "react"

import { useSendPasswordResetLinkMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"

const ResetPassword = () => {

    const [sendPasswordResetLink] = useSendPasswordResetLinkMutation()

    const [error, setError] = useState({
        state: false,
        msg: '',
        error: ''
    })

    const navigate = useNavigate()

    
    const submitHandler = async(e) => {
        e.preventDefault()
        
        const data = new FormData(e.currentTarget)
        const actualData = {
            email: data.get('email'),
        }
        // console.log(actualData)
        const res = await sendPasswordResetLink(actualData)
        // console.log(res)
        if (res.error){
            console.log(res.error)
            setError({
                state: true,
                msg: res.error.data.errors.non_field_errors[0],
                error: 'error'
            })
            setTimeout(()=>{
                handleOnClose();
                navigate('/')
            },8000)
            
        }
        if (res.data ){
            setError({
                state: true,
                msg: res.data.msg,
                error: 'success'
            })
            
        }   
    }


    const handleOnClose = () => {
        setError({
            state: false,
            msg: '',
            error: ''
        })
    }
    return (
        <>
            <Box sx={{  }}>

            

                <Grid container sx={{ height: '50vh', margin: '5vh', pt: 3,mt:18, width: '90%', height: '100%', }} >
                    <Grid item lg={5} md={7} >


                     
                            <Box component='form' id='login-form' noValidate sx={{ p: 3 }} onSubmit={submitHandler}>

                                {/* show Error  */}
                                {error.error ? <Alert severity={error.error} onClose={handleOnClose}>{error.msg}</Alert> : ''}

                                {/* email field  */}
                                <TextField required margin="normal" fullWidth variant="standard" size="small" id='email' name='email' label='Email Address' />

                                <Box textAlign='center'>
                                    <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 5 }}>Send Email</Button>
                                </Box>
                                <NavLink to='/'>Try Login </NavLink>

                            </Box>
                           
                    </Grid>



                    <Grid item lg={7} md={5} sx={{
                        backgroundImage: `url(${Pic1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '400px',
                        backgroundPosition: 'left',
                        display: { sx: 'none', sm: "block" }
                    }}>

                    </Grid>

                </Grid>

            </Box>
        </>
    )
}

export default ResetPassword
