// import { Box, Button, Typography } from "@mui/material"
// import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@mui/material';
// import moment from 'moment/moment';

// import { useGetAppTodayDownloadsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
// import { useEffect, useState } from "react";


// const TodayPerformApps = () => {
//     let srNo = 0;


//     const [todaysAppDownload, setTodaysAppDownload] = useState('')
//     const { data: todaysApp, isSuccess, refetch, isLoading } = useGetAppTodayDownloadsQuery()

//     useEffect(() => {
//         if (todaysApp && isSuccess) {
//             setTodaysAppDownload(todaysApp)

//             refetch()
//         }
//     }, [todaysApp, isSuccess])

//     const handlePlayStoreClick = (package_name) => {
//         window.open(`https://play.google.com/store/apps/details?id=${package_name}`)
//     }

//     return (
//         <>
//             <Box sx={{ mx: 2, mt: 2, mb: 5 }}>
//                 <Box sx={{ my: 1 }}>
//                     {/* <Button sx={{ textTransform: 'none' }}>Todays App Performance</Button> */}
//                     <Typography color="primary">Todays App Performance</Typography>
//                 </Box>

//                 <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
//                     <Box className="table-wrapper" style={{ maxHeight: '700px', overflowY: 'scroll' }}>

//                         <Box>
//                             <TableContainer>
//                                 <Table className='table table-striped'>
//                                     <TableHead>
//                                         <TableRow>
//                                             <TableCell><Typography>App Id</Typography></TableCell>
//                                             <TableCell><Typography>App Logo</Typography></TableCell>
//                                             <TableCell><Typography>Downloads</Typography></TableCell>
//                                             <TableCell><Typography>App Name</Typography></TableCell>
//                                             <TableCell><Typography>Other Details</Typography></TableCell>

//                                             <TableCell><Typography>App Status</Typography></TableCell>
//                                             <TableCell><Typography >Assign Ads</Typography></TableCell>
//                                             {/* <TableCell><Typography sx={{ alignItems: 'center' }}>Enable Ads</Typography></TableCell> */}

//                                         </TableRow>
//                                     </TableHead>
//                                     <TableBody>

//                                         {todaysAppDownload &&
//                                             todaysAppDownload.map(app => (
//                                                 <TableRow key={app.appId}>

//                                                     <TableCell>{app.id}</TableCell>

//                                                     <TableCell>
//                                                         <img src={app.app_logo} alt={app.title} height='50px' className='border border-1 rounded-circle' />
//                                                     </TableCell>

//                                                     <TableCell><Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 5 }}>{app.downloads}</Typography></TableCell>

//                                                     <TableCell>{app.app_name}</TableCell>

//                                                     <TableCell>
//                                                         <Typography
//                                                             sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
//                                                             style={{ cursor: 'pointer' }}
//                                                             title="Click to checkout on Play Store"
//                                                             onClick={() => { handlePlayStoreClick(app.package_name) }}
//                                                         >
//                                                             {app.package_name}
//                                                         </Typography>
//                                                         <Typography sx={{ fontSize: '11px' }}>Created date: {moment(app.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
//                                                         <Typography sx={{ fontSize: '11px' }}>Last update: {moment(app.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
//                                                     </TableCell>



//                                                     <TableCell>
//                                                         {app.app_status === 'Not Published'
//                                                             ?
//                                                             <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5 }} style={{ color: 'gray' }}>{app.app_status}</Typography>
//                                                             :
//                                                             <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} style={{ color: 'gray' }}>{app.app_status}</Typography>
//                                                         }
//                                                     </TableCell>

//                                                     <TableCell>
//                                                         {
//                                                             app.ads_type === "Main Ads"
//                                                                 ?
//                                                                 <Typography style={{ textShadow: '1px  3px 5px green' }}>{app.ads_type}</Typography>
//                                                                 :
//                                                                 <Typography style={{ textShadow: '1px  3px 5px orange' }}>{app.ads_type}</Typography>
//                                                         }
//                                                     </TableCell>
//                                                 </TableRow>
//                                             ))}


//                                     </TableBody>
//                                 </Table>
//                             </TableContainer>
//                         </Box>

//                     </Box>

//                 </Box>
//             </Box>
//         </>
//     )
// }

// export default TodayPerformApps



















import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';

import { useNavigate } from 'react-router-dom';
// todays app data 
import { useGetAppTodayDownloadsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";


const TodayPerformApps = (props) => {

    let srNo = 0;

    const [todayData, setTodayData] = useState([]);
    const [todayCurrentPage, setTodayCurrentPage] = useState(1);
    const [todayRowsPerPage, setTodayRowsPerPage] = useState(8); // Default value for rows per page
    const pageSizeOptions = [8, 15, 20]; // Options for rows per page
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
    const navigate = useNavigate();

    // console.log(props.showLoadingAnimation)

    // API Call
    const { data: todaysApp, isSuccess: isTodaySuccessData, refetch: TodaysData, isLoading: isTodayDataLoading } = useGetAppTodayDownloadsQuery();
    useEffect(() => {
        if (todaysApp && isTodaySuccessData) {
            setTodayData(todaysApp);

            setTimeout(() => {
                setShowLoadingAnimation(false);
            }, 1000);
        }
    }, [todaysApp, isTodaySuccessData]);

    // console.log(AppData)


    // Calculate the index of the first and last rows of the current page
    const todayIndexOfLastRow = todayCurrentPage * todayRowsPerPage;
    const todayIndexOfFirstRow = todayIndexOfLastRow - todayRowsPerPage;

    //   const currentRows = data.slice(todayIndexOfFirstRow, todayIndexOfLastRow);
    // const todayTotalPages = Math.ceil(todaysApp.length / todayRowsPerPage);
   
    const currentRows = todayData
        .filter((item) => item.is_delete === false)
        .slice(todayIndexOfFirstRow, todayIndexOfLastRow);

    // Function to handle pagination
    const handlePageChange = (pageNumber) => {
        setTodayCurrentPage(pageNumber);
    };

    // Function to handle rows per page change
    const handlePageSizeChange = (event) => {
        setTodayRowsPerPage(parseInt(event.target.value));
        setTodayCurrentPage(1); // Reset current page when changing rows per page
    };



    // hadmle playstore click 
    const handlePlayStoreClick = (package_name) => {

        window.open(`https://play.google.com/store/apps/details?id=${package_name}`)
    }

    // console.log(typeof(todayData))

    return (
        <>


            <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
                <Box sx={{ my: 2 }}>
                    <Typography color="primary">Today's App Performance</Typography>
                </Box>

                <div className="data-table-container">
                    {isTodayDataLoading || props.showLoadingAnimation ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                            <CircularProgress />
                            <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                        </Box>
                    ) : (
                        <table className="table table-hover">
                            <thead>
                                <tr>

                                    <th >App Id</th>
                                    <th >App Logo</th>
                                    <th >Today's Downloads</th>
                                    <th >Total Downloads</th>
                                    <th >App Name</th>
                                    <th >Other Details</th>
                                    <th  >Added By</th>
                                    <th  >App Status</th>
                                    <th >Assign Ads</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentRows[0] ?

                                        currentRows.map((item, index) => (

                                            item.is_delete ? '' :

                                                <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'} >



                                                    {/* application Id  */}
                                                    <td><Typography sx={{ marginLeft: 1 }}>{item.id}</Typography></td>

                                                    {/* App Logo  */}
                                                    <td>
                                                        <Box >
                                                            <img src={item.app_logo} alt={item.title} height='50px' className='border border-1 rounded-circle ' style={{ boxShadow: "2px 2px 5px gray" }} />
                                                        </Box>
                                                    </td>

                                                    
                                                    {/* App Todays Downloads  */}
                                                    <td><Typography sx={{ marginLeft: 4 }}>{item.todays_download}</Typography></td>

                                                    {/* App Total downloads   */}
                                                    <td><Typography sx={{ marginLeft: 4 }}>{item.downloads}</Typography></td>

                                                    {/* app Name  */}
                                                    <td><Typography>{item.app_name}</Typography></td>



                                                    {/* other details  */}
                                                    <td>
                                                        <Typography
                                                            sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                            style={{ cursor: 'pointer' }}
                                                            title="Click to checkout on Play Store"
                                                            onClick={() => { handlePlayStoreClick(item.package_name) }}
                                                        >
                                                            {item.package_name}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '11px', display: "inline-block" }}>Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                        <Typography className='ms-5' sx={{ fontSize: '11px', display: "inline-block" }}>Update: {moment(item.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                    </td>

                                                    
                                                    {/* Added User  */}
                                                    <td>
                                                        <Typography sx={{ mt: 2 }} style={{ color: 'gray' }}>{item.added_user}</Typography>
                                                    </td>

                                                    





                                                    {/* App status  */}
                                                    <td>
                                                        {/* {item.app_status === 'Not Published'
                                                            ?
                                                            <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5 }} style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                            :
                                                            <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                        } */}

                                                        {item.app_status === 'Not Published'
                                                            ?
                                                            <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                            :
                                                            item.app_status === 'Published'
                                                                ?
                                                                <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                :
                                                                <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>

                                                        }
                                                    </td>

                                                    {/* Assign Ads */}
                                                    <td>
                                                        {
                                                            item.ads_type === "Main Ads"
                                                                ?
                                                                <Typography style={{ textShadow: '1px  3px 5px green' }}>{item.ads_type}</Typography>
                                                                :
                                                                <Typography style={{ textShadow: '1px  3px 5px orange' }}>{item.ads_type}</Typography>
                                                        }
                                                    </td>

                                                </tr>
                                        ))
                                        :
                                        <tr sx={{ backgroundColor: 'lightgray' }}>

                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><Typography sx={{ p: 2 }}>No Applications</Typography></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                        </tr>


                                }
                            </tbody>
                        </table>
                    )}

                    {/* Pagination */}
                    <div className="pagination-container">

                        <div className="rows-per-page">
                            <span>Rows per page:</span>
                            <select
                                value={todayRowsPerPage}
                                onChange={handlePageSizeChange}
                                className="select-box"
                            >
                                {pageSizeOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="pagination">
                            <button
                                onClick={() => handlePageChange(todayCurrentPage - 1)}
                                disabled={todayCurrentPage === 1}
                            >
                                &lt; {todayCurrentPage - 1}
                            </button>
                            <button
                                onClick={() => handlePageChange(todayCurrentPage)}
                                className="active"
                            >
                                {todayCurrentPage}
                            </button>
                            <button
                                onClick={() => handlePageChange(todayCurrentPage + 1)}
                                disabled={todayIndexOfLastRow >= todayData.length}
                            >
                                {todayCurrentPage + 1} &gt;
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default TodayPerformApps













