import { Alert, Box, Button, Grid, TextField, Typography } from "@mui/material"
import change_reset from '../../Images/change_password.png'

import { useResetPasswordConfirmationMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';

const EmailResetPasswordConfirm = () => {
    const [error, setError] = useState({})
    const [server_error, setServerError] = useState({
        state: false,
        msg: '',
        error: ''
    })
    const { id, token } = useParams()
    const navigate = useNavigate()

    const [resetPasswordConfirmation] = useResetPasswordConfirmationMutation()

    const passwordResetHandler = async (e) => {
        e.preventDefault()

        const data = new FormData(e.currentTarget)
        const actualData = {
            password: data.get('password'),
            password2: data.get('password2')
        }
        console.log(actualData)
        const res = await resetPasswordConfirmation({ actualData, id, token })

        console.log(res)
        if (res.error) {
            if (res.error.data.non_field_errors) {
                setServerError({
                    state: true,
                    msg: res.error.data.non_field_errors[0],
                    error: 'error'
                })
            }

            setError(res.error.data)
        }
        if (res.data) {
            setServerError({
                state: true,
                msg: res.data.msg,
                error: 'success'
            })
            setTimeout(() => {
                handleOnClose();
                navigate('/')
            },2000)
        }
    }

    const handleOnClose = () => {
        setServerError({
            state: false,
            msg: '',
            error: ''
        })
    }

    return (
        <>
            <Box>
                <Grid container sx={{ height: '30vh', margin: '5vh', pt: 3, mt: 18, width: '90%' }} >
                    <Grid item lg={5} md={7}>
                        <Box component='form' id="email-password-reset" noValidate onSubmit={passwordResetHandler}>

                            {server_error.error ? <Alert severity={server_error.error} onClose={handleOnClose}>{server_error.msg}</Alert> : ''}

                            <TextField required fullWidth margin='normal' size="small" id="password" name="password" label='New password' variant="standard" type="password" />
                            {error.password ? <Typography style={{ fontSize: 11, color: 'red' }}>{error.password[0]}</Typography> : ''}

                            <TextField required fullWidth margin='normal' size="small" id="password2" name="password2" label='Confirm new password' variant="standard" type="password" />
                            {error.password2 ? <Typography style={{ fontSize: 11, color: 'red' }}>{error.password2[0]}</Typography> : ''}

                            <Box textAlign='center'>
                                <Button type="submit" variant="contained" sx={{ mt: 3, mv: 2, px: 5 }}>Reset Password</Button>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item lg={7} md={5} sx={{
                        backgroundImage: `url(${change_reset})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '350px',
                        backgroundPosition: 'left',
                        display: { sx: 'none', sm: "block" }
                    }}>

                    </Grid>

                </Grid>
            </Box>
        </>
    )
}

export default EmailResetPasswordConfirm
