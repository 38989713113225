

import Navbar from "../NavigationBar/Navbar";

import Sidebar from '../NavigationBar/Sidebar';
import PageContents from '../MainPageLayout/PageContents';

import { Box, Typography, Grid } from "@mui/material"

const Layout = () => {
  return (
    <>
      <div>

            <Navbar />
        <div className="appWrapper">
          {/* <div className="headerWrapper"> */}
          <div className="headerWrapper">

          </div>

          <div className="appContent">
            <div className="sideMenu">
              <Sidebar />
            </div>
            <div className="pageContentWrapper">
              <PageContents />
            </div>
          </div>
          {/* <footer className="footerWrapper">
        <Box>

        <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={6} >
              <Typography>Powered By ItsDigitalEra</Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={6} >
              <Typography textAlign='right' sx={{px:5}}>Version 2.2</Typography>
               
            </Grid>
          </Grid>
        </Box>
      </footer> */}
        </div>
      </div>
    </>
  );
};

export default Layout;
