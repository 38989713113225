


// to store tokens into a local storage 
const storeToken = (value) =>{

    if (value){

        const {access , refresh} = value
        localStorage.setItem('access_token' , access)
        localStorage.setItem('refresh_token' , refresh)
    }
}


// to access tokens from localstorage 
const getToken = () => {
    let access_token = localStorage.getItem('access_token')
    let refresh_token = localStorage.getItem('refresh_token')

    return {access_token , refresh_token}
}


// to remove token from local storage 
const removeToken = () =>{
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('firstLaunch');
    localStorage.removeItem('previousUrl');

}

export {storeToken , getToken , removeToken}

