

import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@mui/material';

import { useGetAllApplicationsQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
import { useEffect, useState } from "react";
import moment from 'moment/moment';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';

import { useAppSoftDeleteMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
import { useAppPermanentDeleteMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { getToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService";
//add User History 
import { useAddUserHistoryMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { useGetLoggedUserQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";


//get current date time 
const getCurrentDateTime = () => {

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;

}


const TrashApplications = () => {
    const { access_token } = getToken()

    const navigate = useNavigate();
    const [allApplications, setAppAlllications] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    // ============== get User info ==============  
    const [userData, setUserData] = useState({
        email: '',
        name: ''
    })

    const { data, isSuccess, refetch, isLoading } = useGetAllApplicationsQuery(access_token);
    let srNo = 0;
    let count = false;

    useEffect(() => {
        if (data && isSuccess) {
            const loadingTimer = setTimeout(() => {
                setAppAlllications(data);
                setIsLoadingData(false);
                refetch();
            }, 1000);

            return () => clearTimeout(loadingTimer);
        }
    }, [data, isSuccess]);

    // get loged in user data 
    const { data: LogedinUserData, isSuccess: LogedinUserDataSuccess } = useGetLoggedUserQuery(access_token)
    useEffect(() => {
        if (LogedinUserData && LogedinUserDataSuccess) {
            setUserData({
                email: LogedinUserData.email,
                name: LogedinUserData.name
            })
        }

    }, [LogedinUserData, LogedinUserDataSuccess])
    // console.log('userData ',userData)

    const [addUserHistory, { isLoading: isLoadingaddUserHistory, isError: isErroraddUserHistory }] = useAddUserHistoryMutation()



    const [updateResource, { isError }] = useAppSoftDeleteMutation();

    const onRestoreBtn = async (appId, name, app_package_name) => {

        const formData = { is_delete: false, show_ads: true };
        const x = window.confirm('Restore this application ?');

        if (x === true) {
            const res = await updateResource({ appId, formData, access_token });

            if (res.data) {
                //=================== Add logout History ===================  
                const formattedDateTime = getCurrentDateTime()

                // console.log('formattedDateTime :', formattedDateTime)

                const histData = {
                    "user_name": LogedinUserData.name,
                    "package_name": app_package_name,
                    "task_time": formattedDateTime,
                    "task": `Recovered from Trash app "${name}" ,package Name "${app_package_name}"`
                }

                // console.log('histData :', histData)

                const resp = await addUserHistory({ histData, access_token })
                // console.log('resp ', resp)

                //=================== end logout History ===================




                refetch();
            }

            if (res.error) {
                alert('Something went wrong');
            }
        }
    };

    const [deleteResource] = useAppPermanentDeleteMutation();

    const onDeleteBtn = async (appId, name, app_package_name) => {
        const x = window.confirm('Permanently delete this application ?');

        if (x === true) {

            const resp = await deleteResource({ appId, access_token });
            refetch();

            if (resp.data) {

                //=================== Add logout History ===================  
                const formattedDateTime = getCurrentDateTime()

                // console.log('formattedDateTime :', formattedDateTime)

                const histData = {
                    "user_name": LogedinUserData.name,
                    "package_name": app_package_name,
                    "task_time": formattedDateTime,
                    "task": `Permanently Delete  app "${name}" ,package Name "${app_package_name}"`
                }

                // console.log('histData :', histData)

                const resp = await addUserHistory({ histData, access_token })
                // console.log('resp ', resp)

                //=================== end logout History ===================



                refetch();
            }

            if (resp.error) {
                alert('Something went wrong');
            }
        }
    };

    return (
        <>
            <Box>
                <Box sx={{ mx: 2, mt: 2 }}>
                    <Box>
                        <Typography color="primary">
                            <NavLink to="/app-lists" sx={{ color: 'primary' }}>
                                App Lists
                            </NavLink>{' '}
                            / Trash Application
                        </Typography>
                        <hr color="lightgray" />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ mx: 2, mt: 5 }}>
                <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
                    <Typography sx={{ mx: 1, my: 2 }} color="primary">
                        All Trash Application List
                    </Typography>

                    <Box>
                        <Box className="table-wrapper" style={{ maxHeight: '700px', overflowY: 'scroll' }}>
                            {isLoadingData ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300 }}>
                                    <CircularProgress />
                                    <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <TableContainer>
                                        <Table className="table table-striped">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>App Id</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>App Logo</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ marginLeft: 5 }}>App Name</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>Other Details</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ marginLeft: 5 }}>Action</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allApplications.map((row) =>
                                                    row.is_delete ? (
                                                        <TableRow key={row.id}>
                                                            <TableCell>
                                                                {srNo += 1} <span style={{ display: 'Hidden' }}>{count = true}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <img src={row.app_logo} alt={row.title} height="60px" className="border border-1 rounded-circle" style={{ boxShadow: "2px 2px 5px gray" }} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{row.app_name}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}>{row.package_name}</Typography>
                                                                <Typography sx={{ fontSize: '11px' }}>Created date: {moment(row.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                                <Typography sx={{ fontSize: '11px' }}>Last update: {moment(row.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button sx={{ color: 'primary', border: 1, mx: 1 }} onClick={() => { onRestoreBtn(row.id, row.app_name, row.package_name) }}>
                                                                    <RestoreOutlinedIcon />
                                                                </Button>
                                                                <Button sx={{ color: 'red', border: 1 }} onClick={() => { onDeleteBtn(row.id, row.app_name, row.package_name) }}>
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : ''
                                                )}

                                                {count ? (
                                                    ''
                                                ) : (
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell>
                                                            <Typography sx={{ p: 2, color: 'gray' }}>No Trash Application</Typography>
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default TrashApplications;
