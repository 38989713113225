import { Box, Typography, Button, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { NavLink, Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';

import { useGetAdsMonetizePlatformsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"

const AppMonetizePlatform = () => {
    const navigate = useNavigate()
    const { access_token } = useSelector(state => state.auth);
    const [monetizePlatform, setMonetizePlatform] = useState([])

    const { data: getMonetize, isSuccess, refetch } = useGetAdsMonetizePlatformsQuery(access_token)
    // console.log(getMonetize)
    useEffect(() => {
        if (getMonetize && isSuccess) {
            setMonetizePlatform(getMonetize)
            refetch()
        }
    }, [getMonetize, isSuccess])

    const addPlatformHandler = () => {
        navigate('/add-monetize-platform')
    }

    return (
        <>
            <Box >
                <Box sx={{ mx: 2, mt: 2 }} >
                    <div>
                        <Typography color="primary"><NavLink to='/platform' sx={{ color: 'primary' }}>Platform</NavLink> / App Monetize Platform</Typography>
                        <hr color="lightgray" />
                    </div>
                </Box>

                <Box sx={{ mx: 2, mt: 2 }}>
                    <Box sx={{ mt: 1 }} textAlign='right'>
                        <Button variant="contained" onClick={addPlatformHandler} >Add Platform</Button>
                    </Box >
                    
                    <Box sx={{ px: 5, mt: 3, borderRadius: 2, boxShadow: 5 }}>

                        <Grid container spacing={2}>
                            {monetizePlatform.map((platform) => (
                                <Grid item lg={2} xs={12} key={platform.id}>
                                    <Box sx={{ display: 'inline', p: 3 }}  >

                                        <Link to="" >
                                            {/* <div style={{display:'inline-block'}}> */}
                                            <Box style={{ display: 'inline' }}>
                                                <img src={platform.monetize_platformlogo} alt="No Image" height='100px' />

                                            </Box>
                                            {/* <Typography>{platform.monetize_platformname}</Typography> */}
                                            <Box sx={{ mx: 5, mt: 2}}>
                                                <Typography sx={{ fontSize: 14 }}>{platform.monetize_platformname}</Typography>
                                            </Box>
                                            {/* </div> */}


                                        </Link>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>

                    </Box>

                </Box>
            </Box>
        </>
    )
}

export default AppMonetizePlatform
