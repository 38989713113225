import { Box, Grid, Typography } from "@mui/material"
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';

import { useAddMonetizePlatformMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";

function AddMonetizePlatform() {


    const [image, setImage] = useState(null);
    const [text, setText] = useState('');
    const navigate = useNavigate()

    const { access_token } = useSelector(state => state.auth);
    // console.log('access_token :',access_token);
    const [postImage, { isLoading, isError }] = useAddMonetizePlatformMutation()

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('monetize_platformlogo', image);
        formData.append('monetize_platformname', text);
        // formData.append('platformtype', 'publish_plateform');


        try {
            
            const res = await postImage({formData,access_token});
            // Handle successful post
            if (res.data) {
                navigate('/app-monetize-platform')
            }

        } catch (error) {
            // Handle error
            console.error('Error posting image:', error);
        }
    };
    return (
        <>
            <Box >

                <Box sx={{ mx: 2, mt: 2 }} >
                    <div>
                        <Typography color="primary"><NavLink to='/platform' sx={{ color: 'primary' }}>Platform</NavLink> / <NavLink to='/app-monetize-platform' sx={{ color: 'primary' }}>App Monetize Platform</NavLink> / Add Monetize Platform</Typography>
                        <hr color="lightgray" />
                    </div>
                </Box>

                <Box sx={{ mx: 2, mt: 2 }}>

                    <Grid container spacing={2}>

                        <Grid item xs={2}>

                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <Box sx={{ my: 2, px: 4 }}>
                                <Typography color='primary' style={{ fontSize: 'medium' }}>Add Platform</Typography>
                            </Box>

                            <Box sx={{ p: 5, boxShadow: 5, borderRadius: 2 }}>

                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="image">Platform Image:</label>
                                        <input type="file" id="image" accept="image/*" onChange={handleImageChange} className="form-control" />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="text">Platform Name:</label>
                                        <input type="text" id="text" value={text} onChange={handleTextChange} className="form-control" />
                                    </div>
                                    <button type="submit" disabled={!image || isLoading || !text} className="btn btn-success px-5">
                                        {isLoading ? 'Adding...' : 'Add'}
                                    </button>

                                    <button className="btn btn-secondary px-5 mx-2" onClick={() => { navigate('/app-monetize-platform') }}>
                                        Cancel
                                    </button>
                                    {isError && <p style={{ color: 'red' }}>Error Adding platform. Please check input data.</p>}
                                </form>

                            </Box>
                        </Grid>


                        <Grid item xs={2}>

                        </Grid>

                    </Grid>

                </Box>

            </Box>

        </>
    )
}

export default AddMonetizePlatform
