
import { Box, Button, Typography } from "@mui/material"
import AppListTable from "./AppListTable"


const AppLists = () => {

  

  return (
    <>
      <Box >
        <Box sx={{ mx: 2, mt: 2 }} >
          <div>
            <Typography color="primary">App Lists</Typography>
            <hr color="lightgray" />
          </div>
        </Box>
        <Box sx={{mx:2 ,mt:2}} >

          

          <AppListTable />

        </Box>

      </Box>
    </>
  )
}

export default AppLists
