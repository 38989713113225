import { Box, Card, CardContent, Grid, Typography, Button } from '@mui/material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import avatar from '../../Images/user_avatar.png'
import moment from 'moment/moment';

import { getToken } from '../../ApiCalls/AuthenticationsApi/services/LocalStorageService';
import { useChangeAdminStatusMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
import { useGetUserLogsQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';

// get user info by id 
import { useGetUserDetailsByIdQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';

import { useEffect, useState } from 'react';

import UserActivityTable from './UserActivityTable';



const EditUserProfile = () => {
    const { id } = useParams();
    const { access_token } = getToken()
    const navigate = useNavigate()

    const [userLogsData, setUserLogsData] = useState({})
    const [userName, setUserName] = useState('')
    const [adminStats, setAdminStatus] = useState({})
    const [singleUser, setSingleUser] = useState('')


    // post method used to change admin status 
    const [changeAdminStaffStatus, { isLoading: isLoadingaddChangeAdminStatus, isError: isErroraddChangeAdminStatus }] = useChangeAdminStatusMutation()

    // get user info by id 
    const { data: useClickedData, isSuccess: isSuccessUseClickedData, refetch: refetchUseClickedData } = useGetUserDetailsByIdQuery({ id, access_token })

    useEffect(() => {
        if (useClickedData && isSuccessUseClickedData) {
            setSingleUser(useClickedData)
            if (useClickedData.name) {

                setUserName(useClickedData.name)
                // console.log('useClickedData.name ',useClickedData.name)
            }
        }
        


    }, [useClickedData, isSuccessUseClickedData])


    const packageName = ''
    const { data: userLogs, isSuccess: isSuccessUserLogs} = useGetUserLogsQuery({ access_token, userName, packageName })


    useEffect(() => {
        if (userLogs && isSuccessUserLogs && userName) {
            setUserLogsData(userLogs)

        }
    }, [userLogs, isSuccessUserLogs])



    // this methoded called on make admin checkbox 
    const changeAdminStatus = async (status) => {
        setAdminStatus(!status)

    }



    // this method called when save button clicked 
    const handleSaveUserPermission = async () => {
        setSingleUser('')

        const admin_data = {
            admin_status: adminStats
        }


        const resp = await changeAdminStaffStatus({ id, admin_data, access_token })
        // console.log('resp ',resp)
        if (resp.data) {
            refetchUseClickedData()
            navigate('/users')
        } else {
            alert('Network Error')
        }

    }

    // console.log('singleUser ',singleUser)

    return (

        <>
            <Box>
                <Box sx={{ mx: 2, mt: 2 }} >
                    <div>
                        <Typography color="primary"><NavLink to='/users' sx={{ color: 'primary' }}>Users </NavLink> / User Profile</Typography>
                        <hr color="lightgray" />
                    </div>
                </Box>

                <Box sx={{ mx: 2, my: 3 }}>
                    <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>

                        {/* main grid  */}
                        <Grid container spacing={2}>
                            <Grid item lg={6} xs={12}>
                                {/* left Grid  */}
                                <Grid container spacing={2}>
                                    <Grid item lg={4} xs={12}>

                                        <img src={avatar} alt="No avatar" height='100px' id='app_logo' name='app_logo' className='img-fluid' />


                                    </Grid>

                                    <Grid item lg={8} xs={12}>
                                        {/* user Details */}
                                        <Box >
                                            <Typography sx={{ borderBottom: '1px solid gray', pb: 2, color: 'gray' }}>User Details</Typography>
                                        </Box>


                                        <Box sx={{ mt: 2 }}>
                                            <Box>
                                                <Typography fontSize='small' color='gray' sx={{ mt: 1, display: 'flex' }}>
                                                    Name
                                                    <Typography sx={{ mx: 3 }} fontSize='small'>{singleUser.name}</Typography>
                                                </Typography>

                                            </Box>

                                            <Box>
                                                <Typography fontSize='small' color='gray' sx={{ mt: 1, display: 'flex' }}>
                                                    Email
                                                    <Typography sx={{ mx: 3 }} fontSize='small'>{singleUser.email}</Typography>
                                                </Typography>

                                            </Box>

                                            <Box>
                                                <Typography fontSize='small' color='gray' sx={{ mt: 1, display: 'flex' }}>
                                                    Created Date
                                                    <Typography sx={{ mx: 3 }} fontSize='small'>{moment(singleUser.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                </Typography>

                                            </Box>

                                            <Box>
                                                <Typography fontSize='small' color='gray' sx={{ mt: 1, display: 'flex' }}>
                                                    Password Updated
                                                    <Typography sx={{ mx: 3 }} fontSize='small'>{moment(singleUser.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                </Typography>

                                            </Box>

                                        </Box>


                                    </Grid>
                                </Grid>



                            </Grid>

                            {/* right grid  */}
                            <Grid item lg={6} xs={12}>

                                <Box >
                                    <Typography sx={{ borderBottom: '1px solid gray', pb: 2, color: 'gray' }}>User Permissions</Typography>
                                </Box>


                                <Grid container spacing={2}>
                                    <Grid item lg={6} xs={12}>
                                        <Box sx={{ my: 2 }}>
                                            {/* userLogsData.is_admin */}
                                            {
                                                singleUser.is_admin ?
                                                    <input type="checkbox" id='make_admin' className='form-check-input' defaultChecked onClick={() => { changeAdminStatus(singleUser.is_admin) }} />
                                                    :
                                                    <input type="checkbox" id='make_admin' className='form-check-input' onClick={() => { changeAdminStatus(singleUser.is_admin) }} />
                                            }
                                            <label htmlFor="make_admin" style={{ marginLeft: 10 }}>Make Admin</label>

                                        </Box>

                                    </Grid>
                                    <Grid item lg={6} xs={12}>

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        {/* ========================================= Save and cancel button for  Application Settings Tab start from here   =========================================*/}

                        <Box textAlign='center'>
                            <Button onClick={handleSaveUserPermission} sx={{ px: 3, mx: 1 }} variant="contained">Save</Button>
                            <Button sx={{ px: 3, color: 'white', mx: 1 }} className="bg-secondary" onClick={() => { navigate('/users') }}>cancel</Button>

                        </Box>
                        {/* ========================================= Save and cancel button for  Application Settings Tab End here   =========================================*/}



                    </Box>
                </Box>


                {/* user activity table  */}
                <Box sx={{ mx: 2, my: 3 }}>

                    <UserActivityTable userLogs={userLogsData} user_name={userName} />

                </Box>





            </Box>


        </>
    )
}

export default EditUserProfile
