
import { Menu, MenuItem, Button, AppBar, Box, Toolbar, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import Logo from '../Images/mylogo.png'
import user from '../Images/user.png'

import React, { useEffect, useState } from 'react';
import { getToken, removeToken } from "../ApiCalls/AuthenticationsApi/services/LocalStorageService";
import { useDispatch } from "react-redux";
import { unSetUserToken } from "../ApiCalls/AuthenticationsApi/features/authSlice";
// import Changeuserpassword from "./userpages/Changeuserpassword";

import { useGetLoggedUserQuery } from "../ApiCalls/AuthenticationsApi/services/userAuthApi";

// add logout history 
import { useAddUserHistoryMutation } from "../ApiCalls/AuthenticationsApi/services/userAuthApi";


//get current date time 
const getCurrentDateTime = () => {

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;

}


export default function Dashboardnavbar() {

    const [addUserHistory, { isLoading: isLoadingaddUserHistory, isError: isErroraddUserHistory }] = useAddUserHistoryMutation()

    // call APi to get user data 
    const [userData, setUserData] = useState({
        email: '',
        name: ''
    })

    const { access_token } = getToken()
    const { data, isSuccess } = useGetLoggedUserQuery(access_token)
    // console.log(data)
    // setUsername(data.name)

    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    // handle use Logout ========================================================================================
    const dispatch = useDispatch()

    const handleLogout = async () => {


        // add logout history 
        //=================== Add logout History ===================  
        const formattedDateTime = getCurrentDateTime()

        // console.log('formattedDateTime :', formattedDateTime)

        const histData = {
            "user_name": data.name,
            "task_time": formattedDateTime,
            "task": `${data.name} Logout`
        }

        // console.log('histData :', histData)

        const resp = await addUserHistory({ histData, access_token })
        // console.log('resp ', resp)

        //=================== end logout History ===================  


        // Remove token from local storage
        dispatch(unSetUserToken({ access_token: null }));
        removeToken();
        localStorage.removeItem('firstLaunch');
        localStorage.removeItem('previousUrl');
        navigate('/');

        // close menue 
        setAnchorEl(null)
    }

    useEffect(() => {
        if (data && isSuccess) {
            setUserData({
                email: data.email,
                name: data.name
            })
        }
    }, [data])




    return (
        <>
            <Box sx={{ flexGrow: 2 }}>
                <AppBar color="primary" width='100%'>
                    <Toolbar >

                        <Typography variant="h5" component="div" sx={{ flexGrow: 1, marginRight: 20 }}>
                            <Link to='/'><img src={Logo} alt="No Logo" sx={{ color: 'white' }} height='45px' /></Link>
                        </Typography>



                        <Typography sx={{ mx: 2, mt: 1 }}>Welcome {userData.name}</Typography>
                        <Button onClick={handleClick} color="inherit"><img src={user} alt="" height='40px' /></Button>
                        {/* <Button onClick={handleLogout} color="inherit"><img src={user} alt="" height='40px' /></Button> */}
                        {/* <Typography sx={{ color: "red" }}>Logout</Typography> */}

                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 2,
                                '&:before': {
                                    content: '""',
                                    // display: 'inline-block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 30,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}>

                            <MenuItem onClick={handleLogout} ><Typography sx={{ color: "red" }}>Logout</Typography></MenuItem>

                        </Menu>

                    </Toolbar>

                </AppBar>
            </Box>
        </>
    )
}

















// import { Popover, Button, AppBar, Box, Toolbar, Typography } from "@mui/material";
// import { Link, useNavigate } from "react-router-dom";
// import Logo from '../Images/mylogo.png';
// import user from '../Images/user.png';

// import React, { useEffect, useState } from 'react';
// import { getToken, removeToken } from "../ApiCalls/AuthenticationsApi/services/LocalStorageService";
// import { useDispatch } from "react-redux";
// import { unSetUserToken } from "../ApiCalls/AuthenticationsApi/features/authSlice";
// // import Changeuserpassword from "./userpages/Changeuserpassword";

// import { useGetLoggedUserQuery } from "../ApiCalls/AuthenticationsApi/services/userAuthApi";

// export default function Dashboardnavbar() {
//   const [userData, setUserData] = useState({
//     email: '',
//     name: ''
//   });

//   const { access_token } = getToken();
//   const { data, isSuccess } = useGetLoggedUserQuery(access_token);

//   const [anchorEl, setAnchorEl] = useState(null);

//   const navigate = useNavigate();

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   // handle user Logout ========================================================================================
//   const dispatch = useDispatch();

//   const handleLogout = () => {
//     dispatch(unSetUserToken({ access_token: null }));
//     removeToken();
//     navigate('/');
//     setAnchorEl(null);
//   };

//   useEffect(() => {
//     if (data && isSuccess) {
//       setUserData({
//         email: data.email,
//         name: data.name
//       });
//     }
//   }, [data]);

//   return (
//     <>
//       <Box sx={{ flexGrow: 2 }}>
//         <AppBar color="primary" width='100%'>
//           <Toolbar>
//             <Typography variant="h5" component="div" sx={{ flexGrow: 1, marginRight: 20 }}>
//               <Link to='/'><img src={Logo} alt="No Logo" sx={{ color: 'white' }} height='45px' /></Link>
//             </Typography>
//             <Typography sx={{ mx: 2, mt: 1 }}>Welcome {userData.name}</Typography>
//             <Button onClick={handleClick} color="inherit"><img src={user} alt="" height='40px' /></Button>

//             {/* Custom Logout Option */}
//             <Popover
//               open={Boolean(anchorEl)}
//               anchorEl={anchorEl}
//               onClose={handleClose}
//               anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'right',
//               }}
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               anchorReference="anchorEl" // Set anchor reference to "anchorEl"
//               anchorPosition={{ top: 48, right: 16 }} // Adjust position relative to the navbar
//             >
//               <Box sx={{ p: 1 }}>
//                 <Button onClick={handleLogout} color="inherit">Logout</Button>
//               </Box>
//             </Popover>

//           </Toolbar>
//         </AppBar>
//       </Box>
//     </>
//   )
// }
