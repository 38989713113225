

// import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';
// import { useNavigate } from "react-router-dom";
// import { useGetAllApplicationsQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
// import { useEffect, useState } from 'react';
// import moment from 'moment/moment';

// import DeleteIcon from '@mui/icons-material/Delete';
// import ModeIcon from '@mui/icons-material/Mode';
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@mui/material';
// import { useAppSoftDeleteMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
// import { useGetSearchAppsMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';

// import axios from 'axios';


// const AppListTable = () => {
//     let srNo = 0;
//     const [allApplications, setAllApplications] = useState([]);
//     const { data, isSuccess, refetch, isLoading } = useGetAllApplicationsQuery();
//     const [updateResource, { isError }] = useAppSoftDeleteMutation()
//     const [searchApplication] = useGetSearchAppsMutation()
//     const navigate = useNavigate();
//     const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
//     // setShowLoadingAnimation(false)
//     useEffect(() => {
//         if (data && isSuccess) {
//             setShowLoadingAnimation(true);

//             setAllApplications(data);

//             setTimeout(() => {
//                 refetch();
//                 setShowLoadingAnimation(false);
//             }, 1000);
//         }
//     }, [data, isSuccess]);

//     const handleDeleteBtn = async (appId) => {
//         const formData = { is_delete: true, show_ads: false };
//         const confirmDelete = window.confirm('Do you want to delete this application?');
//         if (confirmDelete) {
//             const res = await updateResource({ appId, formData });
//             if (res.data) {
//                 // setShowLoadingAnimation(true);
//                 refetch();
//                 setTimeout(() => {
//                     setShowLoadingAnimation(false);
//                 }, 1000);
//             }
//             if (res.error) {
//                 alert('Something went wrong.');
//             }
//         }
//     };

//     const handleShowAdsToggle = async (appId, adsStatus) => {
//         const adsState = !adsStatus;
//         const formData = { show_ads: adsState };
//         const res = await updateResource({ appId, formData });
//         if (res.data) {

//             refetch();

//         }
//         if (res.error) {
//             alert('Something went wrong.');
//         }
//     };

//     const handleSearch = async (e) => {
//         e.preventDefault();
//         const fd = new FormData(e.currentTarget);
//         const appname = fd.get('appname');
//         const res = await searchApplication(appname);
//         if (res.data) {
//             setAllApplications(res.data);
//         } else {
//             alert('Something went wrong');
//         }
//         document.getElementById('search-form').reset();
//     };


//     const handlePlayStoreClick = (package_name) => {

//         window.open(`https://play.google.com/store/apps/details?id=${package_name}`)
//     }

//     const handleReload = () => {
//         setShowLoadingAnimation(true);
//         refetch();
//         setTimeout(() => {
//             setShowLoadingAnimation(false);
//         }, 1000);
//     }


//     return (
//         <>
//             {/* Add app button */}
//             <Box sx={{ boxShadow: '2px 2px 5px gray', p: 3, borderRadius: 2, mb: 2 }}>
//                 <Grid container spacing={2}>
//                     <Grid item lg={6} xs={12}>
//                         <form onSubmit={handleSearch} id='search-form'>
//                             <input type="text" id='appname' name='appname' placeholder='Search Application' className='form-control w-75' style={{ display: 'inline-block' }} />
//                             <Button type='submit' style={{ display: 'inline-block' }} className='ms-2' sx={{ color: 'primary', border: 1, mx: 1 }}><SearchOutlinedIcon /></Button>
//                         </form>
//                     </Grid>
//                     <Grid item lg={6} xs={12}>
//                         <Box textAlign='right'>
//                             <Button onClick={handleReload}>Reload Apps</Button>

//                             <Button variant="contained" className='bg-danger' sx={{ mx: 1 }} onClick={() => { navigate('/trash-application') }}>Trash Applications</Button>

//                             <Button variant="contained" sx={{ mx: 1, px: 3 }} onClick={() => { navigate('/add-application') }}>Add Application</Button>
//                         </Box>
//                     </Grid>
//                 </Grid>
//             </Box>

//             <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
//                 <Typography sx={{ mx: 1, my: 2 }} color="primary">All Application List</Typography>

//                 <Box>
//                     <Box className="table-wrapper" style={{ maxHeight: '600px', overflowY: 'scroll' }}>
//                         {isLoading || showLoadingAnimation ? (
//                             <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
//                                 <CircularProgress />
//                                 <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
//                             </Box>
//                         ) : (
//                             <Box>
//                                 <TableContainer>
//                                     <Table className='table table-striped'>
//                                         <TableHead>
//                                             <TableRow>
//                                                 <TableCell><Typography sx={{ mx: 2 }}></Typography></TableCell>

//                                                 <TableCell><Typography>App Id</Typography></TableCell>
//                                                 <TableCell><Typography>App Logo</Typography></TableCell>
//                                                 <TableCell><Typography>App Name</Typography></TableCell>
//                                                 <TableCell><Typography>Other Details</Typography></TableCell>
//                                                 <TableCell><Typography>Downloads</Typography></TableCell>
//                                                 <TableCell><Typography>App Status</Typography></TableCell>
//                                                 <TableCell><Typography sx={{ alignItems: 'center' }}>Assign Ads</Typography></TableCell>
//                                                 <TableCell><Typography sx={{ alignItems: 'center' }}>Enable Ads</Typography></TableCell>
//                                                 <TableCell><Typography sx={{ marginLeft: 5 }}>Action</Typography></TableCell>
//                                             </TableRow>
//                                         </TableHead>
//                                         <TableBody>
//                                             {
//                                                 allApplications[0] ?

//                                                     allApplications.map((row) => (
//                                                         row.is_delete ? '' :
//                                                             <TableRow key={row.id}>
//                                                                 <TableCell>{srNo += 1}</TableCell>
//                                                                 <TableCell>{row.id}</TableCell>

//                                                                 <TableCell><img src={row.app_logo} alt={row.title} height='50px' className='border border-1 rounded-circle' /></TableCell>

//                                                                 <TableCell><Typography>{row.app_name}</Typography></TableCell>

//                                                                 <TableCell>
//                                                                     {/* <Typography sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }} style={{cursor:'pointer'}} onClick={()=>{handlePlayStoreClick(row.package_name)}}>{row.package_name}</Typography> */}

//                                                                     <Typography
//                                                                         sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
//                                                                         style={{ cursor: 'pointer' }}
//                                                                         title="Click to checkout on Play Store"
//                                                                         onClick={() => { handlePlayStoreClick(row.package_name) }}
//                                                                     >
//                                                                         {row.package_name}
//                                                                     </Typography>
//                                                                     <Typography sx={{ fontSize: '11px' }}>Created date: {moment(row.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
//                                                                     <Typography sx={{ fontSize: '11px' }}>Last update: {moment(row.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
//                                                                 </TableCell>

//                                                                 <TableCell><Typography sx={{ marginLeft: 4 }}>{row.downloads}</Typography></TableCell>


//                                                                 <TableCell>
//                                                                     {row.app_status === 'Not Published'
//                                                                         ?
//                                                                         <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5 }} style={{ color: 'gray' }}>{row.app_status}</Typography>
//                                                                         :
//                                                                         <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} style={{ color: 'gray' }}>{row.app_status}</Typography>
//                                                                     }
//                                                                 </TableCell>


//                                                                 <TableCell>
//                                                                     {
//                                                                         row.ads_type === "Main Ads"
//                                                                             ?
//                                                                             <Typography style={{ textShadow: '1px  3px 5px green' }}>{row.ads_type}</Typography>
//                                                                             :
//                                                                             <Typography style={{ textShadow: '1px  3px 5px orange' }}>{row.ads_type}</Typography>
//                                                                     }
//                                                                 </TableCell>

//                                                                 <TableCell>
//                                                                     {row.show_ads ? (
//                                                                         <Box className="form-check form-switch">
//                                                                             <input className="form-check-input" type="checkbox" id={`is_enable${row.id}`} defaultChecked onClick={() => { handleShowAdsToggle(row.id, row.show_ads) }} />
//                                                                         </Box>
//                                                                     ) : (
//                                                                         <Box className="form-check form-switch">
//                                                                             <input className="form-check-input" type="checkbox" id={`is_enable${row.id}`} onClick={() => { handleShowAdsToggle(row.id, row.show_ads) }} />
//                                                                         </Box>
//                                                                     )}
//                                                                 </TableCell>
//                                                                 <TableCell>
//                                                                     <Button sx={{ color: 'primary', border: 1, mx: 1, mb: 1 }} onClick={() => { navigate(`/application-details/${row.id}`) }}><ModeIcon /></Button>
//                                                                     <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(row.id) }}><DeleteIcon /></Button>

//                                                                 </TableCell>
//                                                             </TableRow>
//                                                     ))
//                                                     :
//                                                     <TableRow >
//                                                         <TableCell></TableCell>
//                                                         <TableCell></TableCell>
//                                                         <TableCell></TableCell>
//                                                         <TableCell></TableCell>
//                                                         <TableCell><Typography sx={{ p: 1 }}>No Applications</Typography></TableCell>
//                                                         <TableCell></TableCell>
//                                                         <TableCell></TableCell>
//                                                         <TableCell></TableCell>
//                                                         <TableCell></TableCell>
//                                                     </TableRow>
//                                             }
//                                         </TableBody>
//                                     </Table>
//                                 </TableContainer>
//                             </Box>
//                         )}
//                     </Box>
//                 </Box>
//             </Box>
//         </>
//     );
// }

// export default AppListTable;








import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import { Box, Typography, Button, Grid, CircularProgress, Tabs, Tab, Select, MenuItem } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useGetAllApplicationsQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
import { useGetApplicationsDetailsQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
// search api 
import { useGetSearchAppsMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSoftDeleteMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';

// get user info 
import { useGetLoggedUserQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { getToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService"
//add User History 
import { useAddUserHistoryMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"

//get current date time 
const getCurrentDateTime = () => {

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;

}


// change tabs
const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
        <div role='tabpanel' hidden={value !== index}>
            {
                value === index && (<Box>{children}</Box>)
            }

        </div>
    )
}


const AppListTable = () => {

    // ============== to store User info ==============  
    const [userData, setUserData] = useState({
        email: '',
        name: '',
        is_admin: false
    })
    const { access_token } = getToken()
    const { data: LogedinUserData, isSuccess: LogedinUserDataSuccess } = useGetLoggedUserQuery(access_token)

    // add user login history 
    const [addUserHistory, { isLoading: isLoadingaddUserHistory, isError: isErroraddUserHistory }] = useAddUserHistoryMutation()


    useEffect(() => {
        if (LogedinUserData && LogedinUserDataSuccess) {
            setUserData({
                email: LogedinUserData.email,
                name: LogedinUserData.name,
                is_admin: LogedinUserData.is_admin
            })
        }

    }, [LogedinUserData, LogedinUserDataSuccess])

    // ============== get User info end ==============  


    // const [allApplications, setAllApplications] = useState([]);
    const [data, setData] = useState([]);

    // All Apps 
    let AllAppsSrNo = 0;
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Default value for rows per page
    const pageSizeOptions = [10, 15, 20]; // Options for rows per page

    // published Apps
    let PublishedAppsSrNo = 0;
    const [currentPage1, setCurrentPage1] = useState(1);
    const [rowsPerPage1, setRowsPerPage1] = useState(10); // Default value for rows per page
    const pageSizeOptions1 = [10, 15, 20]; // Options for rows per page

    // Not published Apps 
    let NotPublishedAppsSrNo = 0;
    const [currentPage2, setCurrentPage2] = useState(1);
    const [rowsPerPage2, setRowsPerPage2] = useState(10); // Default value for rows per page
    const pageSizeOptions2 = [10, 15, 20]; // Options for rows per page

    // Suspended Apps 
    let SuspendedAppsSrNo = 0;
    const [currentPage3, setCurrentPage3] = useState(1);
    const [rowsPerPage3, setRowsPerPage3] = useState(10); // Default value for rows per page
    const pageSizeOptions3 = [10, 15, 20]; // Options for rows per page

    // My Apps 
    let MyAppsSrNo = 0;
    const [currentPage4, setCurrentPage4] = useState(1);
    const [rowsPerPage4, setRowsPerPage4] = useState(10); // Default value for rows per page
    const pageSizeOptions4 = [10, 15, 20]; // Options for rows per page

    // Change app status
    const [appStatus, setAppStatus] = useState('')



    const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
    const navigate = useNavigate();

    // Tabs
    const [value, setValue] = useState(0);

    // API Call
    const { data: AppData, isSuccess, refetch, isLoading } = useGetAllApplicationsQuery(access_token);
    const [updateResource,] = useAppSoftDeleteMutation()

    useEffect(() => {
        if (AppData && isSuccess) {
            // setShowLoadingAnimation(true);


            setData(AppData);
            refetch();

            setTimeout(() => {
                setShowLoadingAnimation(false);
            }, 1000);
        }
    }, [AppData, isSuccess]);

    // console.log(AppData)

    // console.log("test")

    // ============================================================================= All Apps Tab start from here  ===================================================================================

    // Calculate the index of the first and last rows of the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    //   const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
    // const totalPages = Math.ceil(data.length / rowsPerPage);

    const currentRows = data
        .filter((item) => item.is_delete === false)
        .slice(indexOfFirstRow, indexOfLastRow);



    // Function to handle pagination
    const handlePageChange = (pageNumber) => {
        setShowLoadingAnimation(true);

        setCurrentPage(pageNumber);
        setTimeout(() => {
            setShowLoadingAnimation(false);
        }, 1000);
    };

    // Function to handle rows per page change
    const handlePageSizeChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(1); // Reset current page when changing rows per page
    };
    // ============================================================================= All Apps Tab End here  ===================================================================================


    // ============================================================================= Published Apps Tab start from here  ===================================================================================

    // Calculate the index of the first and last rows of the current page
    const indexOfLastRow1 = currentPage1 * rowsPerPage1;
    const indexOfFirstRow1 = indexOfLastRow1 - rowsPerPage1;
    //   const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
    // const totalPages1 = Math.ceil(data.length / rowsPerPage1);


    // for publish platform 
    const currentRows1 = data
        .filter((item) => item.is_delete === false && item.app_status === "Published")
        .slice(indexOfFirstRow1, indexOfLastRow1);


    // Function to handle pagination
    const handlePageChange1 = (pageNumber1) => {
        setShowLoadingAnimation(true);

        setCurrentPage1(pageNumber1);
        setTimeout(() => {
            setShowLoadingAnimation(false);
        }, 1000);
    };

    // Function to handle rows per page change
    const handlePageSizeChange1 = (event) => {
        setRowsPerPage1(parseInt(event.target.value));
        setCurrentPage1(1); // Reset current page when changing rows per page
    };
    // ============================================================================= Published Apps Tab End here  ===================================================================================

    // ============================================================================= Not Published Apps Tab start from here  ===================================================================================

    // Calculate the index of the first and last rows of the current page
    const indexOfLastRow2 = currentPage2 * rowsPerPage2;
    const indexOfFirstRow2 = indexOfLastRow2 - rowsPerPage2;
    //   const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
    // const totalPages2 = Math.ceil(data.length / rowsPerPage2);


    // for Not publish platform 
    const currentRows2 = data
        .filter((item) => item.is_delete === false && item.app_status === "Not Published")
        .slice(indexOfFirstRow2, indexOfLastRow2);



    // Function to handle pagination
    const handlePageChange2 = (pageNumber2) => {
        setShowLoadingAnimation(true);

        setCurrentPage2(pageNumber2);
        setTimeout(() => {
            setShowLoadingAnimation(false);
        }, 1000);
    };

    // Function to handle rows per page change
    const handlePageSizeChange2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value));
        setCurrentPage2(1); // Reset current page when changing rows per page
    };
    // ============================================================================= Not Published Apps Tab End here  ===================================================================================

    // ============================================================================= suspended Apps Tab start from here  ===================================================================================

    // Calculate the index of the first and last rows of the current page
    const indexOfLastRow3 = currentPage3 * rowsPerPage3;
    const indexOfFirstRow3 = indexOfLastRow3 - rowsPerPage3;
    //   const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
    // const totalPages3 = Math.ceil(data.length / rowsPerPage3);


    // for Not publish platform 
    const currentRows3 = data
        .filter((item) => item.is_delete === false && item.app_status === "Suspended")
        .slice(indexOfFirstRow3, indexOfLastRow3);



    // Function to handle pagination
    const handlePageChange3 = (pageNumber3) => {
        setShowLoadingAnimation(true);

        setCurrentPage3(pageNumber3);
        setTimeout(() => {
            setShowLoadingAnimation(false);
        }, 1000);
    };

    // Function to handle rows per page change
    const handlePageSizeChange3 = (event) => {
        setRowsPerPage3(parseInt(event.target.value));
        setCurrentPage3(1); // Reset current page when changing rows per page
    };
    // ============================================================================= suspended Apps Tab End here  ===================================================================================

    // ============================================================================= My Apps Tab start from here  ===================================================================================

    // Calculate the index of the first and last rows of the current page
    const indexOfLastRow4 = currentPage4 * rowsPerPage4;
    const indexOfFirstRow4 = indexOfLastRow4 - rowsPerPage4;
    //   const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
    // const totalPages4 = Math.ceil(data.length / rowsPerPage4);


    // for Not publish platform 
    // const currentRows4 = data
    //     .filter((item) => item.is_delete === false && item.app_status === "Suspended")
    //     .slice(indexOfFirstRow4, indexOfLastRow4);

    const currentRows4 = data
        .filter((item) => item.is_delete === false && item.added_user === userData.name)
        .slice(indexOfFirstRow4, indexOfLastRow4);

    // console.log('userData.name :',userData.name)

    // Function to handle pagination
    const handlePageChange4 = (pageNumber4) => {
        setShowLoadingAnimation(true);

        setCurrentPage4(pageNumber4);
        setTimeout(() => {
            setShowLoadingAnimation(false);
        }, 1000);
    };

    // Function to handle rows per page change
    const handlePageSizeChange4 = (event) => {
        setRowsPerPage4(parseInt(event.target.value));
        setCurrentPage4(1); // Reset current page when changing rows per page
    };
    // ============================================================================= My Apps Tab End here  ===================================================================================






    // hadle Search 
    const [searchApplication] = useGetSearchAppsMutation()
    const handleSearch = async (e) => {
        e.preventDefault();
        const fd = new FormData(e.currentTarget);
        const appname = fd.get('appname');
        const res = await searchApplication(appname);
        if (res.data) {
            setShowLoadingAnimation(true);

            setData(res.data);
            setCurrentPage(1);
            setRowsPerPage(10);

            setTimeout(() => {
                setShowLoadingAnimation(false);
            }, 1000);
        } else {
            alert('Something went wrong');
        }
        document.getElementById('search-form').reset();
    };

    // hadle reload 
    const handleReload = () => {
        setShowLoadingAnimation(true);

        refetch();

        setTimeout(() => {
            setShowLoadingAnimation(false);
        }, 1000);
        refetch();
    }

    // hadmle playstore click 
    const handlePlayStoreClick = (package_name) => {

        window.open(`https://play.google.com/store/apps/details?id=${package_name}`)
    }



    // handle soft delete 


    const handleDeleteBtn = async (appId, name, app_package_name) => {

        const formData = { is_delete: true, show_ads: false };


        const confirmDelete = window.confirm('Do you want to delete this application?');


        if (confirmDelete) {
            const res = await updateResource({ appId, formData, access_token });
            if (res.data) {
                // setShowLoadingAnimation(true);

                //=================== Add logout History ===================  
                const formattedDateTime = getCurrentDateTime()

                // console.log('formattedDateTime :', formattedDateTime)

                const histData = {
                    "user_name": LogedinUserData.name,
                    "package_name": app_package_name,
                    "task_time": formattedDateTime,
                    "task": `Moved to Trash app "${name}" ,package Name "${app_package_name}"`
                }

                // console.log('histData :', histData)

                const resp = await addUserHistory({ histData, access_token })
                // console.log('resp ', resp)

                //=================== end logout History ===================

                refetch();
                setTimeout(() => {
                    setShowLoadingAnimation(false);
                }, 1000);
            }
            if (res.error) {
                alert('Something went wrong.');
            }
        }
    };


    // change tabs
    const handlerChange = (event, newValue) => {
        setValue(newValue)
    }




    // add previous url 
    const location = useLocation();

    useEffect(() => {
        // Save the current location in local storage or state
        // so it can be accessed later to find the previous URL
        // For example, you can store it in local storage:
        localStorage.setItem('previousUrl', location.pathname);
    }, [location]);

    //   console.log('localStorage :',localStorage.previousUrl)



    return (
        <>
            <Box sx={{ boxShadow: '2px 2px 5px gray', p: 3, borderRadius: 2, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <form onSubmit={handleSearch} id='search-form'>
                            <input type="text" id='appname' name='appname' placeholder='Search Application' className='form-control w-75' style={{ display: 'inline-block' }} />
                            <Button type='submit' style={{ display: 'inline-block' }} className='ms-2' sx={{ color: 'primary', border: 1, mx: 1 }}><SearchOutlinedIcon /></Button>
                        </form>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Box textAlign='right'>
                            <Button onClick={handleReload}>Reload Apps</Button>

                            <Button variant="contained" className='bg-danger' sx={{ mx: 1 }} onClick={() => { navigate('/trash-application') }}>Trash Applications</Button>

                            <Button variant="contained" sx={{ mx: 1, px: 3 }} onClick={() => { navigate('/add-application') }}>Add Application</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>

                {/* Tabs for All Applications Published Apps Not Publish Apps  */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} textColor="primary" indicatorColor="primary" onChange={handlerChange}>
                        <Tab label="All Apps" sx={{ textTransform: 'none', fontWeight: 'bold' }}></Tab>
                        <Tab label="Published Apps" sx={{ textTransform: 'none', fontWeight: 'bold' }} ></Tab>
                        <Tab label="Not Published Apps" sx={{ textTransform: 'none', fontWeight: 'bold' }} ></Tab>
                        <Tab label="Suspended Apps" sx={{ textTransform: 'none', fontWeight: 'bold' }} ></Tab>
                        <Tab label="My Apps" sx={{ textTransform: 'none', fontWeight: 'bold' }} ></Tab>

                    </Tabs>

                </Box>


                {/*==================================================== All Apps Tabs ==================================================== */}
                <TabPanel value={value} index={0}>
                    <div className="data-table-container">
                        {isLoading || showLoadingAnimation ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                <CircularProgress />
                                <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                            </Box>
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th ></th>
                                        <th >App Id</th>
                                        <th >App Logo</th>
                                        <th >App Name</th>
                                        <th >Other Details</th>
                                        <th>Downloads</th>

                                        <th >App Status</th>
                                        <th >Assign Ads</th>
                                        <th >Enable Ads</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRows[0] ?

                                            currentRows.map((item, index) => (

                                                item.is_delete ? '' :

                                                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'} >

                                                        {/* Serial Number  */}
                                                        <td>{AllAppsSrNo += 1}</td>

                                                        {/* application Id  */}
                                                        <td><Typography sx={{ marginLeft: 1 }}>{item.id}</Typography></td>

                                                        {/* App Logo  */}
                                                        <td>
                                                            <Box >
                                                                <img src={item.app_logo} alt={item.title} height='50px' className='border border-1 rounded-circle ' style={{ boxShadow: "2px 2px 5px gray" }} />
                                                            </Box>
                                                        </td>

                                                        {/* app Name  */}
                                                        <td><Typography>{item.app_name}</Typography></td>


                                                        {/* other details  */}
                                                        <td>
                                                            <Typography
                                                                sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                                style={{ cursor: 'pointer' }}
                                                                title="Click to checkout on Play Store"
                                                                onClick={() => { handlePlayStoreClick(item.package_name) }}
                                                            >
                                                                {item.package_name}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '11px', display: "inline-block" }}>Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                            <Typography className='ms-5' sx={{ fontSize: '11px', display: "inline-block" }}>Update: {moment(item.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                        </td>

                                                        {/* App Downloads  */}
                                                        <td><Typography sx={{ marginLeft: 4 }}>{item.downloads}</Typography></td>


                                                        {/* App status  */}
                                                        <td >

                                                            {
                                                                userData.is_admin ?
                                                                    // if user is admin then will show dropdown 
                                                                    <Box>
                                                                        {/* <label htmlFor="app_status" className='form-label'>Application Status</label> */}
                                                                        <Box>
                                                                            <Select placeholder="Select App Statuse" sx={{ width: "100%", maxWidthh: 400, minWidth: 40, height: 40, borderRadius: 30 }}
                                                                                // style={{backgroundColor: item.app_status === 'Not Published' ? "orange" : item.app_status === 'Published' ? "lightgreen" : "red" }}  
                                                                                style={{ boxShadow: item.app_status === 'Not Published' ? '1px 2px 8px orange' : item.app_status === 'Published' ? '1px 2px 8px green' : '1px 2px 8px red' }}
                                                                                id="app_status"
                                                                                name="ads_open_type"
                                                                                value={item.app_status}

                                                                                onChange={async (e) => {

                                                                                    const appStatus = e.target.value
                                                                                    const appId = item.id
                                                                                    const formattedDateTime = getCurrentDateTime()
                                                                                    // setAppStatus(e.target.value)

                                                                                    const histData = {
                                                                                        "user_name": userData.name,
                                                                                        "package_name": item.package_name,
                                                                                        "task_time": formattedDateTime,
                                                                                        "task": `App "${item.app_name}" Details modified ,App Status changed "${item.app_status}" to "${appStatus}"`
                                                                                    }

                                                                                    const formData = { app_status : appStatus };
                                                                

                                                                                    const res = await updateResource({ appId, formData, access_token });
                                                                                    // console.log(res)
                                                                                    if (res.data) {
                                                                                        // setShowLoadingAnimation(true);

                                                                                        const resp = await addUserHistory({ histData, access_token })
                                                                                        // console.log('resp ', resp)


                                                                                        setTimeout(() => {
                                                                                            // setShowLoadingAnimation(false);
                                                                                            refetch();
                                                                                        }, 500);
                                                                                    }

                                                                                }}
                                                                            >

                                                                                <MenuItem value='Not Published' >Not Published</MenuItem>
                                                                                <MenuItem value='Published'>Published</MenuItem>
                                                                                <MenuItem value='Suspended'>Suspended</MenuItem>
                                                                            </Select>


                                                                        </Box>
                                                                    </Box>

                                                                    :

                                                                    // if user is not admin then show on textBox
                                                                    item.app_status === 'Not Published'
                                                                        ?
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                        :
                                                                        item.app_status === 'Published'
                                                                            ?
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                            :
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>




                                                            }

                                                        </td>

                                                        {/* Assign Ads */}
                                                        <td>
                                                            {
                                                                item.ads_type === "Main Ads"
                                                                    ?
                                                                    <Typography style={{ textShadow: '1px  3px 5px green' }}>{item.ads_type}</Typography>
                                                                    :
                                                                    <Typography style={{ textShadow: '1px  3px 5px orange' }}>{item.ads_type}</Typography>
                                                            }
                                                        </td>

                                                        {/* Ads Enable toggle  */}
                                                        <td>
                                                            {item.show_ads ? (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} defaultChecked disabled />
                                                                </Box>
                                                            ) : (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} disabled />
                                                                </Box>
                                                            )}
                                                        </td>

                                                        {/* Action Button  */}
                                                        <td>
                                                            {/* Add actions/buttons for each row as needed */}
                                                            <Button sx={{ color: 'primary', border: 1, mx: 1, mb: 1 }} onClick={() => { navigate(`/application-details/${item.id}`) }} title="Edit application properties"><ModeIcon /></Button>
                                                            {
                                                                item.added_user === LogedinUserData.name || LogedinUserData.is_admin ?
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Trash application"  ><DeleteIcon /></Button>
                                                                    :
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Dont have access to delete" disabled ><DeleteIcon /></Button>
                                                            }

                                                        </td>
                                                    </tr>
                                            ))
                                            :
                                            <tr sx={{ backgroundColor: 'lightgray' }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><Typography sx={{ p: 2 }}>No Applications</Typography></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>


                                    }
                                </tbody>
                            </table>
                        )}

                        {/* Pagination */}
                        <div className="pagination-container">

                            <div className="rows-per-page">
                                <span>Rows per page:</span>
                                <select
                                    value={rowsPerPage}
                                    onChange={handlePageSizeChange}
                                    className="select-box"
                                >
                                    {pageSizeOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="pagination">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    &lt; {currentPage - 1}
                                </button>
                                <button
                                    onClick={() => handlePageChange(currentPage)}
                                    className="active"
                                >
                                    {currentPage}
                                </button>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={indexOfLastRow >= data.length}
                                >
                                    {currentPage + 1} &gt;
                                </button>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                {/*==================================================== All Apps Tabs end ==================================================== */}





                {/*==================================================== Published Apps Tabs Start ==================================================== */}
                <TabPanel value={value} index={1}>
                    <div className="data-table-container">
                        {isLoading || showLoadingAnimation ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                <CircularProgress />
                                <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                            </Box>
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th ></th>
                                        <th >App Id</th>
                                        <th >App Logo</th>
                                        <th >App Name</th>
                                        <th >Other Details</th>
                                        <th>Downloads</th>
                                        <th>App Status</th>
                                        <th >Assign Ads</th>
                                        <th >Enable Ads</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRows1[0] ?

                                            currentRows1.map((item, index) => (

                                                item.app_status === 'Published' ?

                                                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'} >

                                                        {/* Serial Number  */}
                                                        <td>{PublishedAppsSrNo += 1}</td>

                                                        {/* application Id  */}
                                                        <td><Typography sx={{ marginLeft: 1 }}>{item.id}</Typography></td>

                                                        {/* App Logo  */}
                                                        <td>
                                                            <Box >
                                                                <img src={item.app_logo} alt={item.title} height='50px' className='border border-1 rounded-circle ' style={{ boxShadow: "2px 2px 5px gray" }} />
                                                            </Box>
                                                        </td>

                                                        {/* app Name  */}
                                                        <td><Typography>{item.app_name}</Typography></td>


                                                        {/* other details  */}
                                                        <td>
                                                            <Typography
                                                                sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                                style={{ cursor: 'pointer' }}
                                                                title="Click to checkout on Play Store"
                                                                onClick={() => { handlePlayStoreClick(item.package_name) }}
                                                            >
                                                                {item.package_name}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '11px', display: "inline-block" }}>Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                            <Typography className='ms-5' sx={{ fontSize: '11px', display: "inline-block" }}>Update: {moment(item.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                        </td>

                                                        {/* App Downloads  */}
                                                        <td><Typography sx={{ marginLeft: 4 }}>{item.downloads}</Typography></td>

                                                        {/* App status  */}
                                                        <td>
                                                            
                                                        {
                                                                userData.is_admin ?
                                                                    // if user is admin then will show dropdown 
                                                                    <Box>
                                                                        {/* <label htmlFor="app_status" className='form-label'>Application Status</label> */}
                                                                        <Box>
                                                                            <Select placeholder="Select App Statuse" sx={{ width: "100%", maxWidthh: 400, minWidth: 40, height: 40, borderRadius: 30 }}
                                                                                // style={{backgroundColor: item.app_status === 'Not Published' ? "orange" : item.app_status === 'Published' ? "lightgreen" : "red" }}  
                                                                                style={{ boxShadow: item.app_status === 'Not Published' ? '1px 2px 8px orange' : item.app_status === 'Published' ? '1px 2px 8px green' : '1px 2px 8px red' }}
                                                                                id="app_status"
                                                                                name="ads_open_type"
                                                                                value={item.app_status}

                                                                                onChange={async (e) => {

                                                                                    const appStatus = e.target.value
                                                                                    const appId = item.id
                                                                                    const formattedDateTime = getCurrentDateTime()
                                                                                    // setAppStatus(e.target.value)

                                                                                    const histData = {
                                                                                        "user_name": userData.name,
                                                                                        "package_name": item.package_name,
                                                                                        "task_time": formattedDateTime,
                                                                                        "task": `App "${item.app_name}" Details modified ,App Status changed "${item.app_status}" to "${appStatus}"`
                                                                                    }

                                                                                    const formData = { app_status : appStatus };
                                                                

                                                                                    const res = await updateResource({ appId, formData, access_token });
                                                                                    // console.log(res)
                                                                                    if (res.data) {
                                                                                        // setShowLoadingAnimation(true);

                                                                                        const resp = await addUserHistory({ histData, access_token })
                                                                                        // console.log('resp ', resp)


                                                                                        setTimeout(() => {
                                                                                            // setShowLoadingAnimation(false);
                                                                                            refetch();
                                                                                        }, 500);
                                                                                    }

                                                                                }}
                                                                            >

                                                                                <MenuItem value='Not Published' >Not Published</MenuItem>
                                                                                <MenuItem value='Published'>Published</MenuItem>
                                                                                <MenuItem value='Suspended'>Suspended</MenuItem>
                                                                            </Select>


                                                                        </Box>
                                                                    </Box>

                                                                    :

                                                                    // if user is not admin then show on textBox
                                                                    item.app_status === 'Not Published'
                                                                        ?
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                        :
                                                                        item.app_status === 'Published'
                                                                            ?
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                            :
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>




                                                            }

                                                        </td>

                                                        {/* Assign Ads */}
                                                        <td>
                                                            {
                                                                item.ads_type === "Main Ads"
                                                                    ?
                                                                    <Typography style={{ textShadow: '1px  3px 5px green' }}>{item.ads_type}</Typography>
                                                                    :
                                                                    <Typography style={{ textShadow: '1px  3px 5px orange' }}>{item.ads_type}</Typography>
                                                            }
                                                        </td>

                                                        {/* Ads Enable toggle  */}
                                                        <td>
                                                            {item.show_ads ? (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} defaultChecked disabled />
                                                                </Box>
                                                            ) : (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} disabled />
                                                                </Box>
                                                            )}
                                                        </td>

                                                        {/* Action Button  */}
                                                        <td>
                                                            {/* Add actions/buttons for each row as needed */}
                                                            <Button sx={{ color: 'primary', border: 1, mx: 1, mb: 1 }} onClick={() => { navigate(`/application-details/${item.id}`) }} title="Edit application properties"><ModeIcon /></Button>
                                                            {
                                                                item.added_user === LogedinUserData.name || LogedinUserData.is_admin ?
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Trash application"  ><DeleteIcon /></Button>
                                                                    :
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Dont have access to delete" disabled ><DeleteIcon /></Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                    :
                                                    ""
                                            ))
                                            :
                                            <tr sx={{ backgroundColor: 'lightgray' }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><Typography sx={{ p: 2 }}>No Applications</Typography></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>


                                    }
                                </tbody>
                            </table>
                        )}

                        {/* Pagination */}
                        <div className="pagination-container">

                            <div className="rows-per-page">
                                <span>Rows per page:</span>
                                <select
                                    value={rowsPerPage1}
                                    onChange={handlePageSizeChange1}
                                    className="select-box"
                                >
                                    {pageSizeOptions1.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="pagination">
                                <button
                                    onClick={() => handlePageChange1(currentPage1 - 1)}
                                    disabled={currentPage1 === 1}
                                >
                                    &lt; {currentPage1 - 1}
                                </button>
                                <button
                                    onClick={() => handlePageChange1(currentPage1)}
                                    className="active"
                                >
                                    {currentPage1}
                                </button>
                                <button
                                    onClick={() => handlePageChange1(currentPage1 + 1)}
                                    disabled={indexOfLastRow1 >= data.length}
                                >
                                    {currentPage1 + 1} &gt;
                                </button>
                            </div>
                        </div>
                    </div>

                </TabPanel>






                {/*====================== Not  publisedh Apps tab =============================== */}
                <TabPanel value={value} index={2}>
                    <div className="data-table-container">
                        {isLoading || showLoadingAnimation ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                <CircularProgress />
                                <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                            </Box>
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th ></th>
                                        <th >App Id</th>
                                        <th >App Logo</th>
                                        <th >App Name</th>
                                        <th >Other Details</th>
                                        <th>Downloads</th>
                                        <th>App Status</th>
                                        <th >Assign Ads</th>
                                        <th >Enable Ads</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRows2[0] ?

                                            currentRows2.map((item, index) => (

                                                item.app_status === 'Published' ? '' :

                                                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'} >

                                                        {/* Serial Number  */}
                                                        <td>{NotPublishedAppsSrNo += 1}</td>

                                                        {/* application Id  */}
                                                        <td><Typography sx={{ marginLeft: 1 }}>{item.id}</Typography></td>

                                                        {/* App Logo  */}
                                                        <td>
                                                            <Box >
                                                                <img src={item.app_logo} alt={item.title} height='50px' className='border border-1 rounded-circle ' style={{ boxShadow: "2px 2px 5px gray" }} />
                                                            </Box>
                                                        </td>

                                                        {/* app Name  */}
                                                        <td><Typography>{item.app_name}</Typography></td>


                                                        {/* other details  */}
                                                        <td>
                                                            <Typography
                                                                sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                                style={{ cursor: 'pointer' }}
                                                                title="Click to checkout on Play Store"
                                                                onClick={() => { handlePlayStoreClick(item.package_name) }}
                                                            >
                                                                {item.package_name}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '11px', display: "inline-block" }}>Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                            <Typography className='ms-5' sx={{ fontSize: '11px', display: "inline-block" }}>Update: {moment(item.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                        </td>

                                                        {/* App Downloads  */}
                                                        <td><Typography sx={{ marginLeft: 4 }}>{item.downloads}</Typography></td>

                                                        {/* App status  */}
                                                        <td>
                                                            {/* {item.app_status === 'Not Published'
                                                                ?
                                                                <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                :
                                                                item.app_status === 'Published'
                                                                    ?
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                    :
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>

                                                            } */}

{
                                                                userData.is_admin ?
                                                                    // if user is admin then will show dropdown 
                                                                    <Box>
                                                                        {/* <label htmlFor="app_status" className='form-label'>Application Status</label> */}
                                                                        <Box>
                                                                            <Select placeholder="Select App Statuse" sx={{ width: "100%", maxWidthh: 400, minWidth: 40, height: 40, borderRadius: 30 }}
                                                                                // style={{backgroundColor: item.app_status === 'Not Published' ? "orange" : item.app_status === 'Published' ? "lightgreen" : "red" }}  
                                                                                style={{ boxShadow: item.app_status === 'Not Published' ? '1px 2px 8px orange' : item.app_status === 'Published' ? '1px 2px 8px green' : '1px 2px 8px red' }}
                                                                                id="app_status"
                                                                                name="ads_open_type"
                                                                                value={item.app_status}

                                                                                onChange={async (e) => {

                                                                                    const appStatus = e.target.value
                                                                                    const appId = item.id
                                                                                    const formattedDateTime = getCurrentDateTime()
                                                                                    // setAppStatus(e.target.value)

                                                                                    const histData = {
                                                                                        "user_name": userData.name,
                                                                                        "package_name": item.package_name,
                                                                                        "task_time": formattedDateTime,
                                                                                        "task": `App "${item.app_name}" Details modified ,App Status changed "${item.app_status}" to "${appStatus}"`
                                                                                    }

                                                                                    const formData = { app_status : appStatus };
                                                                

                                                                                    const res = await updateResource({ appId, formData, access_token });
                                                                                    // console.log(res)
                                                                                    if (res.data) {
                                                                                        // setShowLoadingAnimation(true);

                                                                                        const resp = await addUserHistory({ histData, access_token })
                                                                                        // console.log('resp ', resp)


                                                                                        setTimeout(() => {
                                                                                            // setShowLoadingAnimation(false);
                                                                                            refetch();
                                                                                        }, 500);
                                                                                    }

                                                                                }}
                                                                            >

                                                                                <MenuItem value='Not Published' >Not Published</MenuItem>
                                                                                <MenuItem value='Published'>Published</MenuItem>
                                                                                <MenuItem value='Suspended'>Suspended</MenuItem>
                                                                            </Select>


                                                                        </Box>
                                                                    </Box>

                                                                    :

                                                                    // if user is not admin then show on textBox
                                                                    item.app_status === 'Not Published'
                                                                        ?
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                        :
                                                                        item.app_status === 'Published'
                                                                            ?
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                            :
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>




                                                            }

                                                        </td>

                                                        {/* Assign Ads */}
                                                        <td>
                                                            {
                                                                item.ads_type === "Main Ads"
                                                                    ?
                                                                    <Typography style={{ textShadow: '1px  3px 5px green' }}>{item.ads_type}</Typography>
                                                                    :
                                                                    <Typography style={{ textShadow: '1px  3px 5px orange' }}>{item.ads_type}</Typography>
                                                            }
                                                        </td>

                                                        {/* Ads Enable toggle  */}
                                                        <td>
                                                            {item.show_ads ? (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} defaultChecked disabled />
                                                                </Box>
                                                            ) : (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} disabled />
                                                                </Box>
                                                            )}
                                                        </td>

                                                        {/* Action Button  */}
                                                        <td>
                                                            {/* Add actions/buttons for each row as needed */}
                                                            <Button sx={{ color: 'primary', border: 1, mx: 1, mb: 1 }} onClick={() => { navigate(`/application-details/${item.id}`) }} title="Edit application properties"><ModeIcon /></Button>
                                                            {
                                                                item.added_user === LogedinUserData.name || LogedinUserData.is_admin ?
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Trash application"  ><DeleteIcon /></Button>
                                                                    :
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Dont have access to delete" disabled ><DeleteIcon /></Button>
                                                            }
                                                        </td>
                                                    </tr>
                                            ))
                                            :
                                            <tr sx={{ backgroundColor: 'lightgray' }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><Typography sx={{ p: 2 }}>No Applications</Typography></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>


                                    }
                                </tbody>
                            </table>
                        )}

                        {/* Pagination */}
                        <div className="pagination-container">

                            <div className="rows-per-page">
                                <span>Rows per page:</span>
                                <select
                                    value={rowsPerPage2}
                                    onChange={handlePageSizeChange2}
                                    className="select-box"
                                >
                                    {pageSizeOptions2.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="pagination">
                                <button
                                    onClick={() => handlePageChange2(currentPage2 - 1)}
                                    disabled={currentPage2 === 1}
                                >
                                    &lt; {currentPage2 - 1}
                                </button>
                                <button
                                    onClick={() => handlePageChange2(currentPage2)}
                                    className="active"
                                >
                                    {currentPage2}
                                </button>
                                <button
                                    onClick={() => handlePageChange2(currentPage2 + 1)}
                                    disabled={indexOfLastRow2 >= data.length}
                                >
                                    {currentPage2 + 1} &gt;
                                </button>
                            </div>
                        </div>
                    </div>

                </TabPanel>





                {/* ============================== Suspended Apps =========================================== */}

                <TabPanel value={value} index={3}>
                    <div className="data-table-container">
                        {isLoading || showLoadingAnimation ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                <CircularProgress />
                                <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                            </Box>
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th ></th>
                                        <th >App Id</th>
                                        <th >App Logo</th>
                                        <th >App Name</th>
                                        <th >Other Details</th>
                                        <th>Downloads</th>
                                        <th>App Status</th>
                                        <th >Assign Ads</th>
                                        <th >Enable Ads</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRows3[0] ?

                                            currentRows3.map((item, index) => (

                                                item.app_status === 'Suspended' ?

                                                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'} >

                                                        {/* Serial Number  */}
                                                        <td>{SuspendedAppsSrNo += 1}</td>

                                                        {/* application Id  */}
                                                        <td><Typography sx={{ marginLeft: 1 }}>{item.id}</Typography></td>

                                                        {/* App Logo  */}
                                                        <td>
                                                            <Box >
                                                                <img src={item.app_logo} alt={item.title} height='50px' className='border border-1 rounded-circle ' style={{ boxShadow: "2px 2px 5px gray" }} />
                                                            </Box>
                                                        </td>

                                                        {/* app Name  */}
                                                        <td><Typography>{item.app_name}</Typography></td>


                                                        {/* other details  */}
                                                        <td>
                                                            <Typography
                                                                sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                                style={{ cursor: 'pointer' }}
                                                                title="Click to checkout on Play Store"
                                                                onClick={() => { handlePlayStoreClick(item.package_name) }}
                                                            >
                                                                {item.package_name}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '11px', display: "inline-block" }}>Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                            <Typography className='ms-5' sx={{ fontSize: '11px', display: "inline-block" }}>Update: {moment(item.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                        </td>

                                                        {/* App Downloads  */}
                                                        <td><Typography sx={{ marginLeft: 4 }}>{item.downloads}</Typography></td>

                                                        {/* App status  */}
                                                        <td>
                                                            {/* {item.app_status === 'Not Published'
                                                                ?
                                                                <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                :
                                                                item.app_status === 'Published'
                                                                    ?
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                    :
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>

                                                            } */}

{
                                                                userData.is_admin ?
                                                                    // if user is admin then will show dropdown 
                                                                    <Box>
                                                                        {/* <label htmlFor="app_status" className='form-label'>Application Status</label> */}
                                                                        <Box>
                                                                            <Select placeholder="Select App Statuse" sx={{ width: "100%", maxWidthh: 400, minWidth: 40, height: 40, borderRadius: 30 }}
                                                                                // style={{backgroundColor: item.app_status === 'Not Published' ? "orange" : item.app_status === 'Published' ? "lightgreen" : "red" }}  
                                                                                style={{ boxShadow: item.app_status === 'Not Published' ? '1px 2px 8px orange' : item.app_status === 'Published' ? '1px 2px 8px green' : '1px 2px 8px red' }}
                                                                                id="app_status"
                                                                                name="ads_open_type"
                                                                                value={item.app_status}

                                                                                onChange={async (e) => {

                                                                                    const appStatus = e.target.value
                                                                                    const appId = item.id
                                                                                    const formattedDateTime = getCurrentDateTime()
                                                                                    // setAppStatus(e.target.value)

                                                                                    const histData = {
                                                                                        "user_name": userData.name,
                                                                                        "package_name": item.package_name,
                                                                                        "task_time": formattedDateTime,
                                                                                        "task": `App "${item.app_name}" Details modified ,App Status changed "${item.app_status}" to "${appStatus}"`
                                                                                    }

                                                                                    const formData = { app_status : appStatus };
                                                                

                                                                                    const res = await updateResource({ appId, formData, access_token });
                                                                                    // console.log(res)
                                                                                    if (res.data) {
                                                                                        // setShowLoadingAnimation(true);

                                                                                        const resp = await addUserHistory({ histData, access_token })
                                                                                        // console.log('resp ', resp)
                                                                                        
                                                                                        
                                                                                        setTimeout(() => {
                                                                                            refetch();
                                                                                            // setShowLoadingAnimation(false);
                                                                                        }, 500);
                                                                                    }

                                                                                }}
                                                                            >

                                                                                <MenuItem value='Not Published' >Not Published</MenuItem>
                                                                                <MenuItem value='Published'>Published</MenuItem>
                                                                                <MenuItem value='Suspended'>Suspended</MenuItem>
                                                                            </Select>


                                                                        </Box>
                                                                    </Box>

                                                                    :

                                                                    // if user is not admin then show on textBox
                                                                    item.app_status === 'Not Published'
                                                                        ?
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                        :
                                                                        item.app_status === 'Published'
                                                                            ?
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                            :
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>




                                                            }
                                                        </td>

                                                        {/* Assign Ads */}
                                                        <td>
                                                            {
                                                                item.ads_type === "Main Ads"
                                                                    ?
                                                                    <Typography style={{ textShadow: '1px  3px 5px green' }}>{item.ads_type}</Typography>
                                                                    :
                                                                    <Typography style={{ textShadow: '1px  3px 5px orange' }}>{item.ads_type}</Typography>
                                                            }
                                                        </td>

                                                        {/* Ads Enable toggle  */}
                                                        <td>
                                                            {item.show_ads ? (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} defaultChecked disabled />
                                                                </Box>
                                                            ) : (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} disabled />
                                                                </Box>
                                                            )}
                                                        </td>

                                                        {/* Action Button  */}
                                                        <td>
                                                            {/* Add actions/buttons for each row as needed */}
                                                            <Button sx={{ color: 'primary', border: 1, mx: 1, mb: 1 }} onClick={() => { navigate(`/application-details/${item.id}`) }} title="Edit application properties"><ModeIcon /></Button>
                                                            {
                                                                item.added_user === LogedinUserData.name || LogedinUserData.is_admin ?
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Trash application"  ><DeleteIcon /></Button>
                                                                    :
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Dont have access to delete" disabled ><DeleteIcon /></Button>
                                                            }
                                                        </td>
                                                    </tr>

                                                    :
                                                    ""
                                            ))
                                            :
                                            <tr sx={{ backgroundColor: 'lightgray' }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><Typography sx={{ p: 2 }}>No Applications</Typography></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>


                                    }
                                </tbody>
                            </table>
                        )}

                        {/* Pagination */}
                        <div className="pagination-container">

                            <div className="rows-per-page">
                                <span>Rows per page:</span>
                                <select
                                    value={rowsPerPage3}
                                    onChange={handlePageSizeChange3}
                                    className="select-box"
                                >
                                    {pageSizeOptions3.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="pagination">
                                <button
                                    onClick={() => handlePageChange3(currentPage3 - 1)}
                                    disabled={currentPage3 === 1}
                                >
                                    &lt; {currentPage3 - 1}
                                </button>
                                <button
                                    onClick={() => handlePageChange3(currentPage3)}
                                    className="active"
                                >
                                    {currentPage3}
                                </button>
                                <button
                                    onClick={() => handlePageChange3(currentPage3 + 1)}
                                    disabled={indexOfLastRow3 >= data.length}
                                >
                                    {currentPage3 + 1} &gt;
                                </button>
                            </div>
                        </div>
                    </div>

                </TabPanel>


                {/* ============================================== My Apps tab ========================================                                         */}

                <TabPanel value={value} index={4}>
                    <div className="data-table-container">
                        {isLoading || showLoadingAnimation ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                <CircularProgress />
                                <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                            </Box>
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th ></th>
                                        <th >App Id</th>
                                        <th >App Logo</th>
                                        <th >App Name</th>
                                        <th >Other Details</th>
                                        <th>Downloads</th>
                                        <th >App Status</th>
                                        <th >Assign Ads</th>
                                        <th >Enable Ads</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRows4[0] ?

                                            currentRows4.map((item, index) => (

                                                item.is_delete ? '' :

                                                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'} >

                                                        {/* Serial Number  */}
                                                        <td>{MyAppsSrNo += 1}</td>

                                                        {/* application Id  */}
                                                        <td><Typography sx={{ marginLeft: 1 }}>{item.id}</Typography></td>

                                                        {/* App Logo  */}
                                                        <td>
                                                            <Box >
                                                                <img src={item.app_logo} alt={item.title} height='50px' className='border border-1 rounded-circle ' style={{ boxShadow: "2px 2px 5px gray" }} />
                                                            </Box>
                                                        </td>

                                                        {/* app Name  */}
                                                        <td><Typography>{item.app_name}</Typography></td>


                                                        {/* other details  */}
                                                        <td>
                                                            <Typography
                                                                sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                                style={{ cursor: 'pointer' }}
                                                                title="Click to checkout on Play Store"
                                                                onClick={() => { handlePlayStoreClick(item.package_name) }}
                                                            >
                                                                {item.package_name}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '11px', display: "inline-block" }}>Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                            <Typography className='ms-5' sx={{ fontSize: '11px', display: "inline-block" }}>Update: {moment(item.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                        </td>

                                                        {/* App Downloads  */}
                                                        <td><Typography sx={{ marginLeft: 4 }}>{item.downloads}</Typography></td>

                                                        {/* App status  */}
                                                        <td >

                                                            {/* {item.app_status === 'Not Published'
                                                                ?
                                                                <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                :
                                                                item.app_status === 'Published'
                                                                    ?
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                    :
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>

                                                            } */}

{
                                                                userData.is_admin ?
                                                                    // if user is admin then will show dropdown 
                                                                    <Box>
                                                                        {/* <label htmlFor="app_status" className='form-label'>Application Status</label> */}
                                                                        <Box>
                                                                            <Select placeholder="Select App Statuse" sx={{ width: "100%", maxWidthh: 400, minWidth: 40, height: 40, borderRadius: 30 }}
                                                                                // style={{backgroundColor: item.app_status === 'Not Published' ? "orange" : item.app_status === 'Published' ? "lightgreen" : "red" }}  
                                                                                style={{ boxShadow: item.app_status === 'Not Published' ? '1px 2px 8px orange' : item.app_status === 'Published' ? '1px 2px 8px green' : '1px 2px 8px red' }}
                                                                                id="app_status"
                                                                                name="ads_open_type"
                                                                                value={item.app_status}

                                                                                onChange={async (e) => {

                                                                                    const appStatus = e.target.value
                                                                                    const appId = item.id
                                                                                    const formattedDateTime = getCurrentDateTime()
                                                                                    // setAppStatus(e.target.value)

                                                                                    const histData = {
                                                                                        "user_name": userData.name,
                                                                                        "package_name": item.package_name,
                                                                                        "task_time": formattedDateTime,
                                                                                        "task": `App "${item.app_name}" Details modified ,App Status changed "${item.app_status}" to "${appStatus}"`
                                                                                    }

                                                                                    const formData = { app_status : appStatus };
                                                                

                                                                                    const res = await updateResource({ appId, formData, access_token });
                                                                                    // console.log(res)
                                                                                    if (res.data) {
                                                                                        // setShowLoadingAnimation(true);

                                                                                        const resp = await addUserHistory({ histData, access_token })
                                                                                        // console.log('resp ', resp)


                                                                                        setTimeout(() => {
                                                                                            refetch();
                                                                                        }, 500);
                                                                                        // setShowLoadingAnimation(false);
                                                                                    }

                                                                                }}
                                                                            >

                                                                                <MenuItem value='Not Published' >Not Published</MenuItem>
                                                                                <MenuItem value='Published'>Published</MenuItem>
                                                                                <MenuItem value='Suspended'>Suspended</MenuItem>
                                                                            </Select>


                                                                        </Box>
                                                                    </Box>

                                                                    :

                                                                    // if user is not admin then show on textBox
                                                                    item.app_status === 'Not Published'
                                                                        ?
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, px: 2 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                        :
                                                                        item.app_status === 'Published'
                                                                            ?
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>
                                                                            :
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} textAlign='center' style={{ color: 'gray' }}>{item.app_status}</Typography>




                                                            }
                                                        </td>

                                                        {/* Assign Ads */}
                                                        <td>
                                                            {
                                                                item.ads_type === "Main Ads"
                                                                    ?
                                                                    <Typography style={{ textShadow: '1px  3px 5px green' }}>{item.ads_type}</Typography>
                                                                    :
                                                                    <Typography style={{ textShadow: '1px  3px 5px orange' }}>{item.ads_type}</Typography>
                                                            }
                                                        </td>

                                                        {/* Ads Enable toggle  */}
                                                        <td>
                                                            {item.show_ads ? (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} defaultChecked disabled />
                                                                </Box>
                                                            ) : (
                                                                <Box className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id={`is_enable${item.id}`} disabled />
                                                                </Box>
                                                            )}
                                                        </td>

                                                        {/* Action Button  */}
                                                        <td>
                                                            {/* Add actions/buttons for each row as needed */}
                                                            <Button sx={{ color: 'primary', border: 1, mx: 1, mb: 1 }} onClick={() => { navigate(`/application-details/${item.id}`) }} title="Edit application properties"><ModeIcon /></Button>
                                                            {
                                                                item.added_user === LogedinUserData.name || LogedinUserData.is_admin ?
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Trash application"  ><DeleteIcon /></Button>
                                                                    :
                                                                    <Button sx={{ color: 'red', border: 1, mx: 1, mb: 1 }} onClick={() => { handleDeleteBtn(item.id, item.app_name, item.package_name) }} title="Dont have access to delete" disabled ><DeleteIcon /></Button>
                                                            }
                                                        </td>
                                                    </tr>
                                            ))
                                            :
                                            <tr sx={{ backgroundColor: 'lightgray' }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><Typography sx={{ p: 2 }}>No Applications</Typography></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>


                                    }
                                </tbody>
                            </table>
                        )}

                        {/* Pagination */}
                        <div className="pagination-container">

                            <div className="rows-per-page">
                                <span>Rows per page:</span>
                                <select
                                    value={rowsPerPage4}
                                    onChange={handlePageSizeChange4}
                                    className="select-box"
                                >
                                    {pageSizeOptions4.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="pagination">
                                <button
                                    onClick={() => handlePageChange4(currentPage4 - 1)}
                                    disabled={currentPage4 === 1}
                                >
                                    &lt; {currentPage4 - 1}
                                </button>
                                <button
                                    onClick={() => handlePageChange4(currentPage4)}
                                    className="active"
                                >
                                    {currentPage4}
                                </button>
                                <button
                                    onClick={() => handlePageChange4(currentPage4 + 1)}
                                    disabled={indexOfLastRow4 >= data.length}
                                >
                                    {currentPage4 + 1} &gt;
                                </button>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                {/*==================================================== My Apps Tabs end ==================================================== */}





            </Box>
        </>
    )
}

// export default ApiLists
export default AppListTable;






