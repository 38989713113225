import { Box, Typography, CircularProgress, Button, Grid } from '@mui/material'

import React from 'react'
import { useState } from 'react';
import moment from 'moment/moment';
import { useEffect } from 'react';

import { getToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService"

import { useGetUserLogsQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';




function AppActivitytable(props) {
    const appData = props.appData
    const userData = props.userData
    const [userLogsData, setUserLogsData] = useState({})

    let { access_token } = getToken()
    const packageName = appData.package_name
    const userName = ''
    const { data: userLogs, isSuccess: isSuccessUserLogs, refetch } = useGetUserLogsQuery({ access_token, userName,packageName })
    useEffect(() => {
        if (userLogs && isSuccessUserLogs) {
            setUserLogsData(userLogs)

        }
        refetch()

    }, [userLogs, isSuccessUserLogs])





    // All Apps 
    // ============================================================================= All Apps Tab start from here  ===================================================================================

    let AllAppsSrNo = 0;
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20); // Default value for rows per page
    const pageSizeOptions = [20, 30, 50, 100]; // Options for rows per page
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
    const [filter, setFilter] = useState('');
    const [date, setDate] = useState('');
    const [tableFlag, setTableFlag] = useState(false)

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };


    // Calculate the index of the first and last rows of the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;


    if (userLogs) {
        var currentRows = userLogs.filter(item =>
            item.user_name.toLowerCase().includes(filter.toLowerCase()) ||
            item.task_time.toLowerCase().includes(filter.toLowerCase()) ||
            item.task.toLowerCase().includes(filter.toLowerCase())
        )
            .slice(indexOfFirstRow, indexOfLastRow);

    }


    // Function to handle pagination
    const handlePageChange = (pageNumber) => {


        setCurrentPage(pageNumber);
        setTimeout(() => {

        }, 1000);
    };

    // Function to handle rows per page change
    const handlePageSizeChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(1); // Reset current page when changing rows per page
    };

    // ============================================================================= All Apps Tab End here  ===================================================================================


    let Sr = 0;
    return (
        <>
            <Box sx={{ my: 3 }}>
                <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
                    <Box sx={{ mb: 3 }}>
                        <Typography sx={{ color: "primary" }}>
                            Application Activity
                        </Typography>

                    </Box>

                    <Box >
                        {/* <Box sx={{ my: 2, maxWidth: "400px", minWidth: "100px" }}> */}
                        <Box sx={{ my: 2 }} >

                            <Grid container spacing={2}>

                                <Grid item lg={3} xs={12} >
                                    <input
                                        type="text"
                                        placeholder="Filter Recods..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                        className='form-control'
                                    />

                                </Grid>
                                <Grid item lg={3} xs={12} >
                                    {/* <input type="date" className='form-control' /> */}

                                </Grid>

                                <Grid item lg={3} xs={12} >
                                    {/* <Button sx={{ backgroundColor: "blue", color: 'white' }} >Filter Data</Button>
                                    <Button sx={{ backgroundColor: "red", color: 'white' }} >Clear Filter</Button> */}

                                </Grid>

                                <Grid item lg={3} xs={12} >
                                    {/* <Button >Yesterdays Record</Button> */}

                                </Grid>

                            </Grid>




                        </Box>
                        <div className="data-table-container">
                            {showLoadingAnimation ?
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                    <CircularProgress />
                                    <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                                </Box>
                                :
                                <table className='table table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Sr</th>
                                            <th>Users</th>
                                            <th >Task </th>
                                            <th>Time</th>
                                            {/* <th>More</th> */}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {


                                            currentRows && currentRows.length > 0 ?

                                                currentRows.map(item => (
                                                    <tr key={item.id} >
                                                        <td><Typography sx={{ py: 1 }}>{Sr += 1}</Typography></td>
                                                        <td><Typography sx={{ py: 1 }}>{item.user_name}</Typography> </td>

                                                        {/* <td>{item.task.replace(",","\n")}</td> */}
                                                        <td>
                                                            <Typography sx={{ py: 1 }}>
                                                                {item.task.split(',').map((taskPart, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {i !== 0 && <br />} {/* Add <br> tag for line breaks except for the first part */}
                                                                        {taskPart.trim()}
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{ py: 1 }}>
                                                                {moment(item.task_time).format('MMMM Do YYYY, h:mm a')}
                                                            </Typography>
                                                        </td>
                                                        {/* <td><Button>More</Button></td> */}

                                                    </tr>



                                                ))

                                                :

                                                <tr sx={{ backgroundColor: 'lightgray' }}>

                                                    <td></td>
                                                    <td></td>
                                                    <td><Typography sx={{ p: 2 }}>No Records</Typography></td>
                                                    <td></td>
                                                    {/* <td></td> */}

                                                </tr>

                                        }
                                    </tbody>
                                </table>

                            }
                            {/* Pagination */}
                            <div className="pagination-container">

                                <div className="rows-per-page">
                                    <span>Rows per page:</span>
                                    <select
                                        value={rowsPerPage}
                                        onChange={handlePageSizeChange}
                                        className="select-box"
                                    >
                                        {pageSizeOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="pagination">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        &lt; {currentPage - 1}
                                    </button>
                                    <button
                                        onClick={() => handlePageChange(currentPage)}
                                        className="active"
                                    >
                                        {currentPage}
                                    </button>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={indexOfLastRow >= userLogsData.length}
                                    >
                                        {currentPage + 1} &gt;
                                    </button>
                                </div>
                            </div>
                        </div>

                    </Box>


                

                </Box>
            </Box >
        </>
    )
}

export default AppActivitytable
