
import { Box, Typography, Button } from "@mui/material"

import {  useNavigate } from "react-router-dom"

const Plateforms = () => {

  const navigate = useNavigate()
  const handleAppPusblishNavigation = () => {
    navigate('/app-publish-platform')
  }

  const handleAppMonetizeNavigation = () => {
    navigate('/app-monetize-platform')
  }

  
  return (
    <>
      <Box >
        <Box sx={{ mx: 2, mt: 2 }} >
          <div>
            <Typography color="primary">Platforms</Typography>
            <hr color="lightgray" />
          </div>
        </Box>

        <Box sx={{ mx: 2, mt: 5 }}>
          <Box textAlign='center'>
            <Button variant='contained' sx={{ px: 4, m: 2 }} onClick={handleAppPusblishNavigation} >App Publish Platform</Button>

            <Button variant='contained' sx={{ px: 4, m: 2 }} onClick={handleAppMonetizeNavigation}>App Monetize Platform</Button>
          </Box>
        </Box>

      </Box>
    </>
  )
}

export default Plateforms
