

import { Button, Box, Tabs, Tab } from "@mui/material"
import { useState } from "react";

// get Notification 
import { useGetNotificationDataQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useEffect } from "react";

// Add oneSignal Data 
import { useAddOneSignalDataMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";


// change onesignal data 
import { useChangeOneSignalDataMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

//add User History 
import { useAddUserHistoryMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";


const getCurrentDateTime = () => {

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;

}



// Notification panel Tab control 
const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
        <div role='tabpanel' hidden={value !== index}>
            {
                value === index && (<Box>{children}</Box>)
            }

        </div>
    )
}


const add_history = async (access_token, addUserHistory, appDetails, userDetails, msg = '') => {
    //=================== Add logout History ===================  
    const formattedDateTime = getCurrentDateTime()

    // console.log('formattedDateTime :', formattedDateTime)
    // console.log('appDetails ',appDetails);
    // console.log('userDetails ',userDetails);

    const histData = {
        "user_name": userDetails.name,
        "package_name": appDetails.package_name,
        "task_time": formattedDateTime,
        "task": `${msg},App Name "${appDetails.app_name}" `
    }

    // console.log('histData :', histData)

    const resp = await addUserHistory({ histData, access_token })
    // console.log('resp ', resp)

    //=================== end logout History ===================



}




function NotificationPanel(props) {

    const { access_token } = useSelector(state => state.auth);

    //=================================== Notification Logic Start from here ============================ 
    const [notificationTabValue, setNotificationTabValue] = useState(0)

    // add user login history 
    const [addUserHistory, { isLoading: isLoadingaddUserHistory, isError: isErroraddUserHistory }] = useAddUserHistoryMutation()


    const notificationTabHandler = (event, notificationTabValue) => {
        setNotificationTabValue(notificationTabValue);
        // tabValue, setTabValue
    };


    // useState for store notification data  Data 
    const [appDetails, setAppDetails] = useState(props.package_name);
    const [userDetails, setUserDetails] = useState(props.userDetails);

    const [storeNotifyData, setStoreNotifyData] = useState([]);
    const [packageName, setPackageName] = useState(props.package_name.package_name);
    const [onesignalAppId, setOnesignalAppId] = useState("");
    const [onesignalRestApiKey, setOnesignalRestApiKey] = useState("");

    // push notification useState
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [image, setImage] = useState('');
    const [notificationURL, setNotificationURL] = useState('');

    // OneSignal useStates 
    // const [imageURL, setImageUrl] = useState('')
    let StoreImageURL = '';


    // get Notification data using GET method 
    const { data: getNotifyData, isSuccess: isSuccessNotifyData, refetch: refetchNotifyData, isLoading: isLoadingNotifyData } = useGetNotificationDataQuery({ packageName, access_token })
    useEffect(() => {
        if (getNotifyData && isSuccessNotifyData) {

            setStoreNotifyData(getNotifyData);
            setOnesignalAppId(getNotifyData.onesignal_appId);
            setOnesignalRestApiKey(getNotifyData.onesignal_restApi_key);
            // setTitle(getNotifyData.title)

        }


    }, [getNotifyData, isSuccessNotifyData])



    // Add oneSignal AppId and OneSignal APi Key 
    const [storeOneSignalData, { isLoading: isLoadingStoreOneSignalData, isError: isErrorStoreOneSignalData }] = useAddOneSignalDataMutation()

    const handleOneSignalData = async (e) => {
        e.preventDefault()

        const oneSignalData = {
            package_name: packageName,
            onesignal_appId: onesignalAppId,
            onesignal_restApi_key: onesignalRestApiKey,
        }


        const res = await storeOneSignalData({ oneSignalData, access_token })
        // console.log(res)
        if (res.data) {
            // add history 
            add_history(access_token, addUserHistory, appDetails, userDetails, 'Added Push Notification "OneSignal APP ID" & "OneSignal REST API Key"')

            alert("oneSignal Data successfully Added")
            refetchNotifyData()
            setNotificationTabValue(0)




        }

    }



    // update oneSignal AppId and OneSignal APi Key 
    const [changeOneSignalData, { isLoading: isLoadingChangeOneSignalData, isError: isErrorChangeOneSignalData }] = useChangeOneSignalDataMutation()

    const handleOneSignalDataChange = async (e) => {
        e.preventDefault()
        const notifyId = storeNotifyData.id;
        const oneSignalData = {

            onesignal_appId: onesignalAppId,
            onesignal_restApi_key: onesignalRestApiKey,
        }


        const res = await changeOneSignalData({ notifyId, oneSignalData, access_token })
        // console.log(res)
        if (res.data) {
            // added history 
            add_history(access_token, addUserHistory, appDetails, userDetails, 'Changed Push Notification "OneSignal APP ID" & "OneSignal REST API Key"')

            alert("oneSignal Data successfully Change")
            refetchNotifyData()
            setNotificationTabValue(0)

        }

    }



    // call oneSignal Server 
    const handleOneSignalCall = async (e) => {
        e.preventDefault()

        const oneSignalData = new FormData();
        oneSignalData.append('title', title)
        oneSignalData.append('message', message)

        if (image) {
            oneSignalData.append('notification_image', image)
        }

        if (notificationURL) {
            oneSignalData.append('notification_url', notificationURL)
        }


        const notifyId = storeNotifyData.id;

        const res = await changeOneSignalData({ notifyId, oneSignalData, access_token })
        // console.log(res.data)
        if (res.data) {

            // set imageUrl for oneSIgnal Api CAll 
            // setImageUrl(res.data.notification_image)

            StoreImageURL = res.data.notification_image;
            // call OneSignal APi method 
            oneSignalApiCall()
        }

        // document.getElementById('notificationForm').reset()
    }


    // this method call oneSignal APi to send notifications 
    const oneSignalApiCall = async () => {

        const formattedDateTime = getCurrentDateTime() //get Time

        // console.log('formattedDateTime :',formattedDateTime)
        const notificationData = {
            app_id: onesignalAppId,
            headings: { en: title },
            contents: { en: message },
            included_segments: ['All'],
            big_picture: StoreImageURL,
            // small_icon: imageURL,
            url: notificationURL,
            // send_after: formattedDateTime, // Schedule the notification for a specific date and time
            data: {
                devs1: 'Nitesh1',
                devs2: 'Nitesh2',
                devs3: 'Nitesh3',
                devs4: 'Nitesh4',

            }



        };
        // console.log('imageURL :',StoreImageURL)

        const headers = {
            Authorization: `Basic ${onesignalRestApiKey}`,
            'Content-Type': 'application/json',
        };

        axios
            .post('https://onesignal.com/api/v1/notifications', notificationData, { headers })
            .then((response) => {
                // console.log('Notification sent successfully:', response.data);

                // Added Notification history 
                add_history(access_token, addUserHistory, appDetails, userDetails, `Push Notification Sent ,"Title" : "${title}","Message" : "${message}",${notificationURL ?notificationURL:''}`)

                setTimeout(() => {

                    alert("Notification sent successfully")
                    setTitle('')
                    setMessage('')
                    setNotificationURL('')
                    setImage('')
                }, 1000)


            })
            .catch((error) => {
                // console.error('Error sending notification:', error.message);
                
                // add notification history 
                add_history(access_token, addUserHistory, appDetails, userDetails, `Push Notification Failed,`)

                alert("Error Check OneSignal Configurations")
            });


    }




    // console.log("storeNotifyData is :", storeNotifyData)





    return (
        <>

            {/* ========= this part notification panel component start from here */}

            <Box sx={{ p: 1, height: "500px" }}>

                {/* <Box sx={{ borderTop: 1, borderColor: 'divider' }}></Box> */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={notificationTabValue} textColor="primary" indicatorColor="primary" onChange={notificationTabHandler}>
                        <Tab label="Sending Push Notofications" sx={{ textTransform: 'none', fontWeight: 'bold' }}></Tab>
                        <Tab label="OneSignal Settings" sx={{ textTransform: 'none', fontWeight: 'bold' }} ></Tab>
                    </Tabs>

                </Box>


                {/* ========================================= Notification tab panel start ============================================= */}
                {/* Sending Push Notofications tab  */}
                <TabPanel value={notificationTabValue} index={0} key={0}>
                    <Box sx={{ px: 4, border: 1, borderRadius: 3, mt: 2, borderColor: 'divider', boxShadow: '2px 2px 8px lightgray', mb: 3 }}>

                        <form onSubmit={handleOneSignalCall}>
                            <Box sx={{ my: 2, width: "100%", minWidth: "400px" }}>

                                {/* title  */}
                                <Box sx={{ my: 3 }}>
                                    <label htmlFor="nofifyTitle" className='form-label' >Notification Title *</label>
                                    <input type="text" id='nofifytitle' name='nofifyTitle' className='form-control' value={title} onChange={(e) => setTitle(e.target.value)} />
                                </Box>

                                {/* Message */}
                                <Box sx={{ my: 3 }}>
                                    <label htmlFor="nofifyMessage" className='form-label' >Notification Message *</label>
                                    <input type="text" id='nofifyMessage' name='nofifyMessage' className='form-control' value={message} onChange={(e) => setMessage(e.target.value)} />
                                    {/* <textarea name="nofifyMessage" id="nofifyMessage" cols="10" rows="10" className='form-control'></textarea> */}

                                </Box>

                                {/* URL (optional) */}
                                <Box sx={{ my: 3 }}>
                                    <label htmlFor="nofifyURL" className='form-label' >Notification URL ( optional )</label>
                                    <input type="text" id='nofifyURL' name='nofifyURL' className='form-control' value={notificationURL} onChange={(e) => setNotificationURL(e.target.value)} />
                                </Box>

                                {/* Image (optional) */}
                                <Box sx={{ my: 3 }}>
                                    <label htmlFor="nofifyImage" className="form-label">Notification Image ( optional )</label>
                                    <input type="file" id="nofifyImage" name="nofifyImage" accept="image/*" className="form-control" onChange={(e) => setImage(e.target.files[0])} />
                                </Box>

                                <Box >
                                    <Button type='submit' sx={{ mx: 1 }} variant="contained" disabled={!title || !message || !onesignalRestApiKey || !onesignalAppId}>Send</Button>
                                </Box>

                            </Box>
                        </form>

                    </Box>
                </TabPanel>


                {/* OneSignal Settings tab  */}
                <TabPanel value={notificationTabValue} index={1} key={1}>
                    <Box sx={{ px: 4, border: 1, borderRadius: 3, mt: 2, borderColor: 'divider', boxShadow: '2px 2px 8px lightgray', mb: 3 }}>

                        {/* <form onSubmit={handleOneSignalData} id="notification-form"> */}
                        <Box component='form' noValidate onSubmit={handleOneSignalData} id="notification-form">
                            <Box sx={{ my: 2, width: "100%", minWidth: "400px" }}>

                                {/* title  */}
                                <Box sx={{ my: 3 }}>
                                    <label htmlFor="OneSignalId" className='form-label' >OneSignal APP ID *</label>
                                    <input type="text" id='OneSignalId' name='OneSignalId' className='form-control' style={{ color: "gray" }} value={onesignalAppId} onChange={(e) => setOnesignalAppId(e.target.value)} />
                                </Box>



                                {/* URL (optional) */}
                                <Box sx={{ my: 3 }}>
                                    <label htmlFor="OneSignalKey" className='form-label' >OneSignal REST API Key *</label>
                                    <input type="text" id='OneSignalKey' name='OneSignalKey' className='form-control' style={{ color: "gray" }} value={onesignalRestApiKey} onChange={(e) => setOnesignalRestApiKey(e.target.value)} />
                                </Box>


                                <Box >
                                    {storeNotifyData.onesignal_appId ?

                                        <Button sx={{ mx: 1 }} variant="contained" onClick={handleOneSignalDataChange} disabled={!onesignalAppId || !onesignalRestApiKey}>Change</Button>
                                        :
                                        <Button type='submit' sx={{ mx: 1 }} variant="contained" disabled={!onesignalAppId || !onesignalRestApiKey}>Save</Button>
                                    }
                                </Box>

                            </Box>
                            {/* </form> */}
                        </Box>

                    </Box>
                </TabPanel>

                {/* ========================================= Notification tab panel end ============================================= */}

            </Box>
            {/* ========= this part notification panel component end here */}

        </>

    )
}

export default NotificationPanel
