
import { Box, Typography } from "@mui/material"

const ChangePlatforms = () => {
  return (
    <>
      <Box >
        <Box sx={{ mx: 2, mt: 2 }} >
          <div>
            <Typography color="primary">Change Platforms</Typography>
            <hr color="lightgray" />
          </div>
        </Box>

        <Box sx={{ mx: 2, mt: 2 }} >

          <Typography sx={{ my: 3 }}>Coming Soon in Future Update</Typography>
        </Box>

      </Box>
    </>
  )
}

export default ChangePlatforms
