import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { userAuthApi  } from '../services/userAuthApi'


// import auth slic
import authReducer from '../features/authSlice'

export const store = configureStore({
    reducer: {
        [userAuthApi.reducerPath]: userAuthApi.reducer,
        // setup auth slice 
        auth:authReducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(userAuthApi.middleware),

})

setupListeners(store.dispatch)