import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Plateforms from '../Pages/Plateform/Plateforms';
import AppLists from '../Pages/AppLists/AppLists';
import AppPublishedPlateForm from '../Pages/Plateform/AppPublishedPlateForm';
import AppMonetizePlatform from '../Pages/Plateform/AppMonetizePlatform';
import AddPublishPlatform from '../Pages/Plateform/AddPublishPlatform';
import AddApplication from '../Pages/AppLists/AddApplication';
import TrashApplications from '../Pages/AppLists/TrashApplications';
import ApplicationDetails from '../Pages/AppLists/ApplicationDetails';
import ApiLists from '../Pages/ApiLists/ApiLists';
import TestAds from '../Pages/TestAds/TestAds';
import ChangePlatforms from '../Pages/ChangePlateforms/ChangePlatforms';
import AppActivityLog from '../Pages/AppActivityLog/AppActivityLog';
import AppNotification from '../Pages/AppNotification/AppNotification';
import Users from '../Pages/Users/Users';
import ChangeUserPassword from '../Components/Auth/ChangeUserPassword';
import UserProfile from '../Components/Auth/UserProfile';
import AddMonetizePlatform from '../Pages/Plateform/AddMonetizePlatform';
import AllUserLogs from '../Pages/Users/AllUserLogs';
import { getToken } from '../ApiCalls/AuthenticationsApi/services/LocalStorageService';
import { useGetLoggedUserQuery } from '../ApiCalls/AuthenticationsApi/services/userAuthApi';
import { useEffect } from 'react';
import { useState } from 'react';
import EditUserProfile from '../Pages/Users/EditUserProfile';

// Import other page components

const DashboardRoutes = () => {
    const { access_token } = getToken()
    const [logedUser, setLogedUser] = useState({
        name: '',
        email: '',
        is_admin :false
    })

    const { data: logedinUserDetails, isSuccess: isSuccessLogedinUserDetails } = useGetLoggedUserQuery(access_token)
    useEffect(() => {
        if (logedinUserDetails && isSuccessLogedinUserDetails) {
            setLogedUser({
                name: logedinUserDetails.name,
                email: logedinUserDetails.email,
                is_admin :logedinUserDetails.is_admin 
            })
        }
    },[logedinUserDetails , isSuccessLogedinUserDetails])


    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}

            <Route path="/platform" element={<Plateforms />} />

            <Route path="/app-lists" element={<AppLists />} />

            <Route path='/app-publish-platform' element={<AppPublishedPlateForm />} />
            <Route path='/add-publish-platform' element={<AddPublishPlatform />} />
            <Route path='/app-monetize-platform' element={<AppMonetizePlatform />} />
            <Route path='/add-monetize-platform' element={<AddMonetizePlatform />} />


            {/* appLists routes */}
            <Route path="/add-application" element={<AddApplication />} />
            <Route path="/trash-application" element={<TrashApplications />} />
            <Route path="/application-details/:id" element={<ApplicationDetails />} />

            {/* api lists  */}
            <Route path='/api-lists' element={<ApiLists />} />

            {/* Test Ads  */}
            <Route path='test-ads' element={<TestAds />} />

            {/* change-platform */}
            <Route path='change-platform' element={<ChangePlatforms />} />

            {/* app-activity-log */}
            <Route path='app-activity-log' element={<AppActivityLog />} />

            {/* app-notifications */}
            <Route path='app-notifications' element={<AppNotification />} />

            {/* user profile  */}
            <Route path='change-user-password' element={<ChangeUserPassword />} />
            <Route path='user-profile' element={<UserProfile />} />

            {/* users  */}
            <Route path='users' element={ logedUser.is_admin ? <Users /> :<Dashboard />} />

            {/* All users logs  */}
            <Route path='all-users-logs' element={logedUser.is_admin ? <AllUserLogs /> :<Dashboard />} />


            {/* edit-user-profile */}
            <Route path='/edit-user-profile/:id' element={logedUser.is_admin ? <EditUserProfile /> :<Dashboard />} />




            {/* Add more routes for other pages */}
        </Routes>
    );
};

export default DashboardRoutes;